import { Box, Container, Stack, Typography } from "@mui/material";
import { PricingPlan } from "../pricing/pricing-plan";
import Cube from "../../assets/images/cube-image.png";

const PRICING_PLANS = [
  {
    currency: "$",
    description: "Lay a solid foundation for your cubing skills",
    features: [
      "600 credits",
      "10 lesson package",
      "Equivalent to $60/lesson",
      "",
    ],
    image: "/static/pricing/plan1.svg",
    name: "Competent",
    popular: false,
    price: "600",
    color: "#FFAB00",
  },
  {
    currency: "$",
    description:
      "Take your cubing skills to the next level with our Master class",
    features: [
      "1200 credits",
      "20 lesson package",
      "Equivalent to $54/lesson",
      ,
      "10% savings",
    ],
    image: "/static/pricing/plan2.svg",
    name: "Master",
    popular: true,
    price: "1080",
    color: "#00A76F",
  },
  {
    currency: "$",
    description: "Embark on a journey towards mastering advanced techniques",
    features: [
      "2400 credits",
      "40 lesson package",
      "Equivalent to $48/lesson",
      ,
      "20% savings",
    ],
    image: "/static/pricing/plan3.svg",
    name: "Proficient",
    price: "1920",
    color: "#FF5630",
  },
];

const HomePricing = () => {
  return (
    <Container
      id="pricing-section"
      sx={{
        py: { xs: 10, md: 15 },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          textAlign: "center",
          mb: { xs: 5, md: 10 },
        }}
      >
        <Box>
          <Typography variant="overline" sx={{ color: "text.disabled" }}>
            PRICING PLANS
          </Typography>
        </Box>

        <Box>
          <Typography variant="h2">Credits Combos</Typography>
        </Box>
        <Box>
          <Typography variant="h5">
            Choose the perfect plan for your needs. Always flexible to grow
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              mb: 2,
            }}
          >
            <img src={Cube} alt="Cube image" width={25} />
            <Typography variant="subtitle" ml={2} color="textSecondary">
              1 Hour online class over video conferencing
            </Typography>
          </Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={Cube} alt="Cube image" width={25} />
            <Typography variant="subtitle" ml={2} color="textSecondary">
              Specialised fixed trainer - student paring{" "}
            </Typography>
          </Box>
        </Box>
      </Stack>

      <Box
        gap={{ xs: 3, lg: 10 }}
        display="grid"
        alignItems="center"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          md: "repeat(3, 1fr)",
        }}
      >
        {PRICING_PLANS.map((pricingPlan) => (
          <PricingPlan
            key={pricingPlan.cta}
            pricingPlan={pricingPlan}
            sx={{
              height: "100%",
              maxWidth: 460,
              mx: "auto",
            }}
          />
        ))}
      </Box>
    </Container>
  );
};

export default HomePricing;
