import { Alert, Box, Button, Dialog, Stack, Typography } from "@mui/material";
import { Iconify } from "../../utils/iconify";

export const DialogConfirm = ({
  onClose,
  open,
  method,
  errorMsg,
  title,
  description,
}) => {
  return (
    <Dialog onClose={onClose} open={!!open}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      <Stack spacing={3} sx={{ m: 3 }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Iconify icon="fluent:alert-16-filled" width={20} />
          <Typography>
            <b>{title}</b>
          </Typography>
        </Box>
        <Typography>{description}</Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", m: 3, gap: 2 }}>
          <Button variant="outlined" onClick={method}>
            Yes
          </Button>
          <Button variant="contained" onClick={onClose}>
            No
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
};
