import * as Yup from "yup";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

// components
import Background from "../assets/sample/sample6.png";
import Overlay from "../assets/background/overlay_2.jpg";
import Mofunclass from "../assets/logo/Mofunclass.png";

import Image from "../assets/images/cube-image.png";
import {
  alpha,
  Alert,
  Button,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link, useNavigate } from "react-router-dom";
import { Iconify } from "../utils/iconify";
import { useTheme } from "@emotion/react";
import { bgGradient } from "../utils/css";
import FormProvider from "../components/hook-form/form-provider";
import { useForm } from "react-hook-form";
import RHFTextField from "../components/hook-form/rhf-text-field";
import { useBoolean } from "../components/hook-form/use-boolean";
import { useAuthContext } from "../hooks/useContext";
import { handleError } from "../utils/errors";
import supabase from "../supabase/config";
import { GuestGuard } from "../authentication/guest-guard";

// ----------------------------------------------------------------------

export default function LoginView() {
  const theme = useTheme();
  const { login } = useAuthContext();
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    password: Yup.string().required("Password is required"),
  });

  const password = useBoolean();

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await login({ email: data.email, password: data.password });
      navigate("/");
    } catch (error) {
      setErrorMsg(error.message);
      reset();
    }
  });

  const renderLogo = (
    <Box
      sx={{
        zIndex: 9,
        position: "absolute",
        m: { xs: 2, md: 5 },
      }}
    >
      <Link to="/">
        <img src={Mofunclass} width={150} />
      </Link>
    </Box>
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: "auto",
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        alignSelf: "center",
      }}
    >
      <Stack spacing={2} sx={{ mb: 5 }}>
        <Typography variant="h4">Sign in to MofunClass</Typography>
        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <Link to="/register" style={{ textDecoration: "none" }}>
            <Typography variant="subtitle2" sx={{ color: "#00A76F" }}>
              Create an account
            </Typography>
          </Link>
        </Stack>
      </Stack>

      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack spacing={2.5}>
          {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
          <RHFTextField name="email" label="Email address" />

          <RHFTextField
            autoComplete="new-password"
            name="password"
            label="Password"
            type={password.value ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={password.onToggle} edge="end">
                    <Iconify
                      icon={
                        password.value
                          ? "solar:eye-bold"
                          : "solar:eye-closed-bold"
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Link
            to="/forget-password"
            color="inherit"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "end",
                color: "text.primary",
                textDecoration: "underline",
              }}
            >
              Forgot password?
            </Typography>
          </Link>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Login
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      spacing={10}
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === "light" ? 0.88 : 0.94
          ),
          imgUrl: Overlay,
        }),
        display: { xs: "none", md: "inline-flex" },
      }}
    >
      <Box component="img" alt="auth" src={Image} sx={{ maxWidth: 450 }} />
    </Stack>
  );

  return (
    <GuestGuard>
      <Stack
        component="main"
        direction="row"
        sx={{
          height: "100vh",
        }}
      >
        {renderLogo}

        {renderSection}

        {renderContent}
      </Stack>
    </GuestGuard>
  );
}
