import { useState } from "react";
import DialogBookLesson from "./dialog-book-lesson";
import DialogBookingConfimed from "./dialog-booking-confirmed";

export default function DialogBookingStep({
  onClose,
  open,
  timeslot,
  selDate,
  trainer,
}) {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    const newStep = currentStep + 1;
    setCurrentStep(newStep);
  };

  if (currentStep === 1)
    return (
      <DialogBookLesson
        nextStep={nextStep}
        onClose={onClose}
        open={open}
        timeslot={timeslot}
        selDate={selDate}
        trainer={trainer}
      />
    );
  else if (currentStep === 2)
    return (
      <DialogBookingConfimed
        onClose={onClose}
        open={open}
        timeslot={timeslot}
        trainer={trainer}
      />
    );
}
