import { Avatar, Box, Button, Card, Typography } from "@mui/material";
import Label from "../common/label";
import { Iconify } from "../../utils/iconify";
import {
  formatTimestampEEEDDMMMYYY,
  getTimeFromTimestamp,
} from "../../utils/time";
import { DialogConfirm } from "../common/dialog-confirm";
import { useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { getInitials } from "../../utils/get-initials";
import { useSnackbar } from "../snackbar";
import { useAuthContext, useLessonContext } from "../../hooks/useContext";

export default function LessonTrainer({ lesson, updateLessonStatus }) {
  const theme = useTheme();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { user, authIsReady } = useAuthContext();
  const [method, setMethod] = useState(null);
  const buttonRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationDescription, setConfirmationDescription] = useState("");
  const { startLesson, endLesson, cancelLesson } = useLessonContext();
  // console.log(lesson);

  const handleOpenDialog = (selectedMethod) => {
    switch (selectedMethod) {
      case "cancelLesson":
        setMethod(() => handleCancelLesson);
        setConfirmationTitle("Approve the lesson?");
        setConfirmationDescription("Click “Yes” to cancel the lesson.");
        break;
      case "startCompleteLesson":
        setMethod(() => handleStartCompleteLesson);
        setConfirmationTitle(
          lesson.lessonStatus === null
            ? "Start the lesson?"
            : "Mark lesson as completed?"
        );
        setConfirmationDescription(
          lesson.lessonStatus === null
            ? "Click “Yes” to start the lesson."
            : "Click “Yes” to mark the lesson as completed."
        );
        break;
      default:
        setMethod(null);
    }
    setIsOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const copyToClipboard = () => {
    if (buttonRef.current) {
      const buttonText = buttonRef.current.textContent;
      const tempInput = document.createElement("input");
      document.body.appendChild(tempInput);
      tempInput.value = buttonText;
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      enqueueSnackbar("copied to clipboard");
    }
  };

  const handleCancelLesson = async () => {
    try {
      await cancelLesson({
        id: lesson.id,
        availabilityId: lesson.availabilityId,
        creditsRequired: lesson.creditsRequired,
        studentId: lesson.studentId,
      });
      handleCloseDialog();
      enqueueSnackbar("Lesson cancelled successful");
    } catch (error) {
      enqueueSnackbar(error, "error");
    }
  };

  const handleStartCompleteLesson = async () => {
    try {
      if (!lesson.lessonStatus) {
        await startLesson({ id: lesson.id });
        handleCloseDialog();
        enqueueSnackbar("Lesson started");
      }
      if (lesson.lessonStatus === "started") {
        await endLesson({
          id: lesson.id,
          actualStartTime: lesson.actualStartTime,
          trainerId: lesson.trainerId,
          creditsRequired: lesson.creditsRequired,
          studentId: lesson.studentId,
        });
        handleCloseDialog();
        enqueueSnackbar("Lesson marked as completed");
      }
    } catch (error) {
      enqueueSnackbar(error, "error");
    }
  };

  return (
    <Card>
      <Box
        sx={{
          m: 3,
          display: "flex",
          // justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Label type={lesson.status} />
              <Box
                sx={{
                  alignSelf: "start",
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <Typography variant="caption">UID</Typography>
                <Button
                  startIcon={<Iconify icon="bx:copy" width={18} />}
                  ref={buttonRef}
                  onClick={copyToClipboard}
                  sx={{ backgroundColor: "rgba(145,158,171,0.08)" }}
                >
                  <Typography variant="caption">{lesson.id}</Typography>
                </Button>
              </Box>
            </Box>
            <Typography variant="h5" sx={{ mt: 2 }}>
              1-1 session with {lesson.studentName}
            </Typography>
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Iconify icon="fluent:calendar-32-regular" width={24} />
                <Typography variant="body2">
                  {formatTimestampEEEDDMMMYYY(lesson.startTime)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Iconify icon="basil:clock-outline" width={24} />
                <Typography variant="body2">
                  {`${getTimeFromTimestamp(
                    lesson.startTime
                  )} - ${getTimeFromTimestamp(lesson.endTime)}`}
                </Typography>
              </Box>
            </Box>
            <Typography variant="body2" sx={{ mt: 2 }}>
              {lesson.areaOfFocus}
            </Typography>
            <Box
              sx={{
                mt: 3,
                alignItems: "center",
                gap: 2,
                display: "flex",
              }}
            >
              {lesson.status !== "cancelled" &&
                lesson.lessonStatus !== "started" && (
                  <Button
                    component="a"
                    size="medium"
                    // sx={{ mr: 2 }}
                    variant="outlined"
                    onClick={() => handleOpenDialog("cancelLesson")}
                  >
                    Cancel Lesson
                  </Button>
                )}
              {lesson.lessonStatus === "started" && (
                <Button
                  component="a"
                  size="medium"
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.secondary.main,
                    ":hover": {
                      backgroundColor: theme.palette.secondary.lighter,
                    },
                  }}
                  onClick={() => window.open(user.data.meetingLink, "_blank")}
                >
                  Join Lesson
                </Button>
              )}
              {lesson.status !== "cancelled" && (
                <Button
                  startIcon={
                    lesson.lessonStatus === "started" && (
                      <Iconify
                        icon="charm:tick-double"
                        width={24}
                        color={theme.palette.info.main}
                      />
                    )
                  }
                  sx={{
                    color: theme.palette.info.main,
                    backgroundColor: theme.palette.info.light,
                    ":hover": {
                      backgroundColor: theme.palette.info.lighter,
                    },
                  }}
                  component="a"
                  size="medium"
                  variant="contained"
                  onClick={() => handleOpenDialog("startCompleteLesson")}
                >
                  {lesson.lessonStatus === "started" &&
                    "Mark Lesson as Complete"}
                  {lesson.lessonStatus === null && "Start Lesson"}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Avatar
          alt="profile"
          src={lesson.studentPhotoUrl}
          sx={{
            width: 128,
            height: 128,
            backgroundColor: "#000",
            fontSize: 42,
          }}
        >
          {getInitials(lesson.studentName)}
        </Avatar>
      </Box>
      <DialogConfirm
        title={confirmationTitle}
        description={confirmationDescription}
        onClose={handleCloseDialog}
        open={isOpenDialog}
        method={method}
        errorMsg={errorMsg}
      />
    </Card>
  );
}
