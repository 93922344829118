import {
  Box,
  Card,
  Container,
  Grid,
  Tab,
  Tabs,
  tabsClasses,
} from "@mui/material";
import displayPhoto from "../assets/sample/sample2.png";
import coverPhoto from "../assets/sample/sample3.png";
import ProfileCover from "../components/trainer-profile/profile-cover";
import { useCallback, useEffect, useState } from "react";
import { Iconify } from "../utils/iconify";
import ProfileTab from "../components/trainer-profile/profile-tabs";
import photoUrl from "../assets/sample/sample4.png";
import { useDocument } from "../hooks/useDocument";
import { useParams } from "react-router-dom";
import { useCollection, useCollection_s } from "../hooks/useCollection";
import { getEndTimestamp, getStartTimestamp } from "../utils/time";
import supabase from "../supabase/config";
import { MainLayout } from "../components/main-layout";
import { AuthGuard } from "../authentication/auth-guard";

const TABS = [
  {
    value: "profile",
    label: "Profile",
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: "reviews",
    label: "Reviews",
    icon: <Iconify icon="solar:bill-list-outline" width={20} />,
  },
];

export const TrainerProfileView = () => {
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState("profile");
  const [trainer, setTrainer] = useState(null);

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  useEffect(() => {
    const fetchTrainerData = async () => {
      const { data, error } = await supabase
        .from("users")
        .select("*")
        .eq("id", id);
      if (data) setTrainer(data[0]);
    };
    fetchTrainerData();
  }, []);
  // const { documents, error: colError } = useCollection_s(`availability`, [
  //   ["trainerId", "==", id],
  //   ["date", ">=", getStartTimestamp()],
  //   ["date", "<=", getEndTimestamp()],
  // ]);

  return (
    <AuthGuard>
      <MainLayout>
        {trainer && (
          <Container maxWidth="lg">
            <Card
              sx={{
                mt: 3,
                mb: 3,
                height: 340,
                position: "relative",
              }}
            >
              <ProfileCover
                name={trainer.name}
                country={trainer.country}
                photoUrl={trainer.photoUrl}
              />

              <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                sx={{
                  width: 1,
                  bottom: 0,
                  zIndex: 9,
                  position: "absolute",
                  bgcolor: "background.paper",
                  [`& .${tabsClasses.flexContainer}`]: {
                    pl: { sm: 3 },
                    pt: { sm: 2, md: 4 },
                    justifyContent: {
                      xs: "center",
                      md: "flex-start",
                    },
                  },
                }}
              >
                {TABS.map((tab) => (
                  <Tab
                    key={tab.value}
                    value={tab.value}
                    icon={tab.icon}
                    label={tab.label}
                    iconPosition="start"
                    sx={{ gap: 1 }}
                  />
                ))}
              </Tabs>
            </Card>

            <ProfileTab trainer={trainer} currentTab={currentTab} />
          </Container>
        )}
      </MainLayout>
    </AuthGuard>
  );
};
