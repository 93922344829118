import { Box, Card, Container, Stack, Typography, alpha } from "@mui/material";
import Icon1 from "../../assets/components/three-by-three.png";
import Icon2 from "../../assets/components/online-class.png";
import Icon3 from "../../assets/components/stats.png";

const CARDS = [
  {
    icon: Icon1,
    title: "For all Cubing Enthusiasts",
    description: "Suited for all cube enthusiasts of all skill level. ",
  },
  {
    icon: Icon2,
    title: "Remote Cubing Class",
    description:
      "Save the hassle to travelling. Access personalised cubing classes right where you are",
  },
  {
    icon: Icon3,
    title: "Tiered Cubing Approach",
    description:
      "Curated by Mofunland to provide a comprehensive guide for cubers on their path to mastering the art of cubing.",
  },
];
export default function HomeAboutUs() {
  return (
    <Container
      id="about-section"
      sx={{
        py: { xs: 10, md: 15 },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          textAlign: "center",
          mb: { xs: 5, md: 10 },
        }}
      >
        <Box>
          <Typography variant="overline" sx={{ color: "text.disabled" }}>
            ABOUT US
          </Typography>
        </Box>

        <Box>
          <Typography variant="h2">What is Mofunclass?</Typography>
        </Box>
      </Stack>

      <Box
        gap={{ xs: 3, lg: 10 }}
        display="grid"
        alignItems="center"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          md: "repeat(3, 1fr)",
        }}
      >
        {CARDS.map((card, index) => (
          <Box key={card.title}>
            <Card
              sx={{
                textAlign: "center",
                boxShadow: { md: "none" },
                bgcolor: "background.default",
                p: (theme) => theme.spacing(10, 5),
                ...(index === 1 && {
                  boxShadow: (theme) => ({
                    md: `-40px 40px 80px ${
                      theme.palette.mode === "light"
                        ? alpha(theme.palette.grey[500], 0.16)
                        : alpha(theme.palette.common.black, 0.4)
                    }`,
                  }),
                }),
              }}
            >
              <Box
                component="img"
                src={card.icon}
                alt={card.title}
                sx={{ mx: "auto", width: 48, height: 48 }}
              />

              <Typography variant="h5" sx={{ mt: 8, mb: 2 }}>
                {card.title}
              </Typography>

              <Typography sx={{ color: "text.secondary" }}>
                {card.description}
              </Typography>
            </Card>
          </Box>
        ))}
      </Box>
    </Container>
  );
}
