import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import CurriculumImage from "../../assets/images/curriculum.png";
import { Iconify } from "../../utils/iconify";
import { Link } from "react-router-dom";

export default function HomeCurriculum() {
  return (
    <Box
      sx={{
        py: { xs: 10, md: 15 },
      }}
    >
      <Container maxWidth="lg">
        <Grid
          alignItems="center"
          container
          justifyContent="center"
          spacing={10}
          sx={{
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Grid item md={6} sm={8} xs={12}>
            <Box sx={{ maxWidth: "100%", height: "auto" }}>
              <img
                alt="For designers"
                src={CurriculumImage}
                style={{ width: "100%" }}
              />
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <Typography variant="overline" sx={{ color: "text.secondary" }}>
              Mofunclass Curriculum
            </Typography>
            <Typography variant="h2">Our Tiered Cubing Approach</Typography>
            <Typography
              color="textSecondary"
              sx={{ my: 3 }}
              variant="subtitle1"
            >
              Embark on a meticulously structured journey toward cubing mastery,
              and bid farewell to extensive trial and error and YouTube searches
            </Typography>
            <Box>
              <Link to="/trainers">
                <Button
                  variant="outlined"
                  endIcon={<Iconify icon="ep:arrow-right" width={24} />}
                >
                  Book a class
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
