import { Box, Button, Typography } from "@mui/material";
import PageNotFoundImage from "../../assets/images/page-not-found.png";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { MainLayout } from "../../components/main-layout";

export default function PageNotFoundView() {
  const theme = useTheme();
  return (
    <MainLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "calc(100vh - 110px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Typography variant="h3">Page not found</Typography>
          <Typography variant="body1">
            The page you are looking for does not exist
          </Typography>
          <Box>
            <img
              src={PageNotFoundImage}
              alt="coming soon"
              style={{ width: 89 }}
            />
          </Box>
          <Link to="/">
            <Button
              sx={{
                backgroundColor: "#00A76F",
                color: "#fff",
                ":hover": {
                  backgroundColor: theme.palette.secondary.dark,
                },
              }}
            >
              Homepage
            </Button>
          </Link>
        </Box>
      </Box>
    </MainLayout>
  );
}
