import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Skeleton,
  Stack,
} from "@mui/material";

export const SkeletonPost = () => (
  <Grid item md={3} sm={6} xs={12}>
    <Card sx={{ backgroundColor: "transparent", boxShadow: 0 }}>
      <CardMedia>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={275}
          sx={{ borderRadius: "5px" }}
        />
      </CardMedia>
      <CardContent sx={{ pt: 3, pb: 0 }}>
        <Skeleton animation="wave" height={20} width="40%" />
        <Skeleton animation="wave" height={20} />
        <Skeleton animation="wave" height={20} />
      </CardContent>
    </Card>
  </Grid>
);
