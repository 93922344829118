import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import ProfileGeneral from "../components/user-profile/profile-general";
import ProfileCredits from "../components/user-profile/profile.credits";
import Sample2 from "../assets/sample/sample2.png";
import { useAuthContext } from "../hooks/useContext";
import { formatTimestampEEEDDMMMYYY } from "../utils/time";
import { getInitials } from "../utils/get-initials";
import { useNavigate, useParams } from "react-router-dom";
import { CopyToClipboard } from "../components/common/copy-to-clipboard";
import supabase from "../supabase/config";
import { MainLayout } from "../components/main-layout";
import { AuthGuard } from "../authentication/auth-guard";

const TABS_STUDENT = [
  { label: "General", value: "general" },
  { label: "Credits", value: "credits" },
];

const TABS_TRAINER = [{ label: "General", value: "general" }];

export default function UserProfileView() {
  const queryParams = new URLSearchParams(window.location.search);
  const tab = queryParams.get("tab");
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState(tab ? tab : "general");
  const { user, logout } = useAuthContext();

  // console.log(user);

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthGuard>
      <MainLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Container maxWidth="lg">
            <Typography variant="h3" sx={{ mt: 3 }}>
              Account
            </Typography>
            <Tabs value={currentTab} onChange={handleChangeTab} sx={{ mt: 3 }}>
              {user?.userType === "student" &&
                TABS_STUDENT.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              {user?.userType === "trainer" &&
                TABS_TRAINER.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
            </Tabs>
            <Grid container spacing={3} sx={{ mt: 3 }}>
              <Grid item xs={12} md={4}>
                <Card
                  sx={{
                    pt: 10,
                    pb: 10,
                    px: 3,
                    mb: 3,
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 6,
                  }}
                >
                  <Avatar
                    src={user?.photoUrl}
                    sx={{
                      width: 128,
                      height: 128,
                      backgroundColor: "#000",
                      fontSize: 42,
                    }}
                  >
                    {getInitials(user?.name)}
                  </Avatar>
                  <Stack spacing={1}>
                    <Typography variant="h6">{user?.name}</Typography>
                    <Typography variant="body2">{`Joined ${formatTimestampEEEDDMMMYYY(
                      user?.created_at
                    )}`}</Typography>
                    <CopyToClipboard
                      label="Referral Code"
                      text={user?.referralCode}
                      variant="success"
                    />
                  </Stack>
                </Card>
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </Grid>
              {currentTab === "general" && <ProfileGeneral />}
              {currentTab === "credits" && <ProfileCredits />}
            </Grid>
          </Container>
        </Box>
      </MainLayout>
    </AuthGuard>
  );
}
