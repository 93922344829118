import { Box, Button, Typography } from "@mui/material";
import Label from "./common/label";
import { Iconify } from "../utils/iconify";
import {
  formatTimestampEEEDDMMMYYY,
  getTimeFromTimestamp,
} from "../utils/time";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { DialogConfirm } from "./common/dialog-confirm";
import supabase from "../supabase/config";
import { useAuthContext, useLessonContext } from "../hooks/useContext";
import { useSnackbar } from "./snackbar";

export default function PendingClass({
  lesson,
  stack = false,
  updateLessonStatus,
}) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { user, authIsReady } = useAuthContext();
  const [isExpand, setIsExpand] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [method, setMethod] = useState(null);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationDescription, setConfirmationDescription] = useState("");
  const { approveLesson, cancelLesson } = useLessonContext();

  const handleApprove = async () => {
    try {
      await approveLesson({
        id: lesson.id,
        studentId: lesson.studentId,
        availabilityId: lesson.availabilityId,
      });
      handleCloseDialog();
      enqueueSnackbar("Lesson approved successful");
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", "error");
    }
  };

  const handleDecline = async () => {
    try {
      await cancelLesson();
      handleCloseDialog();
      enqueueSnackbar("Lesson cancelled successful");
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", "error");
    }
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  return (
    <Box
      sx={{
        border: "1px solid rgba(145, 158, 171, 0.16)",
        borderRadius: "8px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography variant="subtitle1">
            1:1 Session with {lesson.studentName}
          </Typography>
          <Label type={lesson.status} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: !stack && "column",
            gap: stack ? 3 : 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Iconify icon="fluent:calendar-32-regular" width={20} />
            <Typography variant="body2">
              {formatTimestampEEEDDMMMYYY(lesson.startTime)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Iconify icon="basil:clock-outline" width={20} />
            <Typography variant="body2">
              {`${getTimeFromTimestamp(
                lesson.startTime
              )} - ${getTimeFromTimestamp(lesson.endTime)}`}
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" sx={{ textDecoration: "underline" }}>
          Area of Focus
        </Typography>
        <Typography variant="body2">{lesson.areaOfFocus}</Typography>
        {!isExpand && (
          <Box
            sx={{ display: "flex", gap: 1, ":hover": { cursor: "pointer" } }}
            onClick={() => setIsExpand(true)}
          >
            <Typography variant="body2" sx={{ color: "#00A76F" }}>
              Read more
            </Typography>
            <Iconify icon="iconamoon:arrow-down-2-light" color="#00A76F" />
          </Box>
        )}
        {isExpand && (
          <>
            <Typography variant="body2" sx={{ textDecoration: "underline" }}>
              Mastered
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: !stack && "column",
                gap: stack ? 3 : 1,
              }}
            >
              {lesson.concepts.map((concept) => (
                <Box sx={{ display: "flex", gap: 1 }} key={concept}>
                  <Iconify icon="ion:checkbox" />
                  <Typography variant="body2">{concept}</Typography>
                </Box>
              ))}
            </Box>
            <Typography variant="body2" sx={{ textDecoration: "underline" }}>
              To Achieve
            </Typography>
            <Typography variant="body2">{lesson.achieve}</Typography>
          </>
        )}
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button variant="outlined" onClick={() => handleDecline()}>
            Decline
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#00A76F",
              color: "#fff",
              ":hover": {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
            onClick={() => handleApprove()}
          >
            Approve
          </Button>
        </Box>
      </Box>
      <DialogConfirm
        title={confirmationTitle}
        description={confirmationDescription}
        onClose={handleCloseDialog}
        open={isOpenDialog}
        method={method}
        errorMsg={errorMsg}
      />
    </Box>
  );
}
