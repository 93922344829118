import {
  alpha,
  Box,
  Container,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ClassesImage from "../assets/images/classes-image.png";
import Overlay from "../assets/background/overlay.png";
import { useTheme } from "@emotion/react";
import { useCollection } from "../hooks/useCollection";
import { bgGradient } from "../utils/css";
import { useAuthContext } from "../hooks/useContext";
import LessonUser from "../components/lesson/lesson-user";
import { TrialPromotionDialog } from "../components/dialog/trial-promotion-dialog";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "../components/snackbar";
import { CopyToClipboard } from "../components/common/copy-to-clipboard";
import { NoLessons } from "../components/common/no-lessons";
import { NoLessonFilter } from "../components/common/no-lessons_filter";
import supabase from "../supabase/config";

const TABS_LESSONS = [
  { label: "Upcoming", value: "upcoming" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Missed", value: "missed" },
];

export const UserLessonsView = () => {
  const theme = useTheme();
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [documents, setDocuments] = useState(null);
  const [currentTab, setCurrentTab] = useState("upcoming");
  const [openPromotionDialog, setOpenPromotionDialog] = useState(
    user.eligableForTrialClass
  );

  const tabToStatus = (tab) => {
    switch (tab) {
      case "upcoming":
        return "confirmed";
      case "cancelled":
        return "cancelled";
      case "missed":
        return "missed";
    }
  };

  useEffect(() => {
    const fetchLessonsData = async () => {
      const { data, error } = await supabase.rpc("getstudentlessons", {
        row_id: user.id,
      });
      if (data) setDocuments(data);
    };
    fetchLessonsData();
  }, []);

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const lessons = documents?.filter(
    (doc) => doc.status == tabToStatus(currentTab)
  );

  const handleCloseDialog = async () => {
    setOpenPromotionDialog(false);
  };

  const handleTryNextTime = async () => {
    try {
      const { error } = await supabase
        .from("users")
        .update({ eligableForTrialClass: false })
        .eq("id", user.id);
      if (error) throw error;
      else setOpenPromotionDialog(false);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  return (
    <>
      <TrialPromotionDialog
        onClose={handleCloseDialog}
        open={openPromotionDialog}
        handleTryNextTime={handleTryNextTime}
      />

      {documents && (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Box
            sx={{
              ...bgGradient({
                color: alpha(
                  theme.palette.background.default,
                  theme.palette.mode === "light" ? 0.9 : 0.94
                ),
                imgUrl: Overlay,
              }),
              // backgroundColor: "blue",
              height: 350,
              position: "relative",
              mt: -8,
              mb: { xs: 4, md: 8 },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                right: 0,
                top: "15%",
                display: { xs: "none", md: "inline" },
              }}
            >
              <img src={ClassesImage} />
            </Box>
            <Container maxWidth="lg">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    mt: 6,
                  }}
                >
                  <Typography variant="h3">Your Lessons</Typography>
                  <Typography variant="body1" color="text.secondary">
                    View your upcoming lessons here
                  </Typography>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="subtitle1" color="text.secondary">
                        Refer a friend
                      </Typography>
                      <CopyToClipboard
                        text={user.referralCode}
                        variant="success"
                      />
                    </Box>
                    <Typography variant="body1" color="text.secondary">
                      With every 10 referrals, you get <b>60</b> credits
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      For every referral who subscribes to a Mofunclass package,
                      <b> 10%</b> incentive in credits will be added to your
                      account.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>

          <Container maxWidth="lg">
            {documents.length === 0 && <NoLessons />}
            {documents.length !== 0 && (
              <>
                <Box
                  sx={{
                    mb: 4,
                    display: "flex",
                    justifyContent: { xs: "center", md: "start" },
                  }}
                >
                  <Tabs value={currentTab} onChange={handleChangeTab}>
                    {TABS_LESSONS.map((tab) => (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                      />
                    ))}
                  </Tabs>
                </Box>
                {lessons.length !== 0 && (
                  <Stack spacing={3}>
                    {lessons.map((lesson) => (
                      <LessonUser lesson={lesson} key={lesson.id} />
                    ))}
                  </Stack>
                )}
                {lessons.length === 0 && <NoLessonFilter tab={currentTab} />}
              </>
            )}
          </Container>
        </Box>
      )}
    </>
  );
};
