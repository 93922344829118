import {
  Box,
  Card,
  CardHeader,
  Container,
  Grid,
  Stack,
  Typography,
  alpha,
  CardActions,
  CardContent,
} from "@mui/material";
import { bgGradient } from "../utils/css";
import { useTheme } from "@emotion/react";
import Overlay from "../assets/background/overlay.png";
import ClassesImage from "../assets/images/classes-image.png";
import PendingClass from "../components/pending-class";
import { Link } from "react-router-dom";
import { Iconify } from "../utils/iconify";
import LessonTrainer from "../components/lesson/lesson-trainer";
import { useAuthContext, useLessonContext } from "../hooks/useContext";
import { useEffect, useState } from "react";
import supabase from "../supabase/config";

export const TrainerLessonsView = () => {
  const theme = useTheme();
  const { lessons } = useLessonContext();
  const [documents, setDocuments] = useState(null);

  const updateLessonStatus = (id, status, isStatus) => {
    const updatedDocuments = documents.map((doc) =>
      isStatus
        ? doc.id === id
          ? { ...doc, status: status }
          : doc
        : doc.id === id
        ? { ...doc, lessonStatus: status }
        : doc
    );
    setDocuments(updatedDocuments);
  };

  const pendingClasses = lessons?.filter((d) => d.status == "pending");
  const remainingClasses = lessons?.filter((d) => d.status == "confirmed");

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Box
          sx={{
            ...bgGradient({
              color: alpha(
                theme.palette.background.default,
                theme.palette.mode === "light" ? 0.9 : 0.94
              ),
              imgUrl: Overlay,
            }),
            // backgroundColor: "blue",
            height: 350,
            position: "relative",
            mt: -8,
            mb: 8,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: "15%",
            }}
          >
            <img src={ClassesImage} />
          </Box>
          <Container maxWidth="lg">
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}
            >
              <Box>
                <Typography variant="h3" sx={{ mt: 3 }}>
                  Your Lessons
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ mt: 3 }}
                >
                  View your upcoming lessons here
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>

        <Container maxWidth="lg">
          <Grid container spacing={2} sx={{ pt: 5 }}>
            <Grid item md={8} sm={6} xs={12}>
              <Stack spacing={3}>
                {remainingClasses.map((lesson) => (
                  <LessonTrainer
                    lesson={lesson}
                    key={lesson.id}
                    updateLessonStatus={updateLessonStatus}
                  />
                ))}
              </Stack>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              {pendingClasses.length > 0 && (
                <Card>
                  <CardHeader title="Pending Classes" />
                  <Stack spacing={3} sx={{ p: 3 }}>
                    {pendingClasses.map((lesson) => (
                      <PendingClass
                        lesson={lesson}
                        key={lesson.id}
                        updateLessonStatus={updateLessonStatus}
                      />
                    ))}
                  </Stack>
                  {pendingClasses.length > 1 && (
                    <CardActions>
                      <Link
                        to={`/trainer/classes/pending`}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            ":hover": { cursor: "pointer" },
                          }}
                        >
                          <Iconify icon="iconamoon:arrow-down-2-light" />
                          <Typography variant="body2">View more</Typography>
                        </Box>
                      </Link>
                    </CardActions>
                  )}
                </Card>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
