import { Box, Button, Drawer, Link, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAuthContext } from "../hooks/useContext";

import { useNavigate } from "react-router-dom";
import supabase from "../supabase/config";

const MainSidebarLink = styled(Link)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: "block",
  padding: theme.spacing(1.5),
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const MainSidebar = (props) => {
  const { onClose, open } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { dispatch } = useAuthContext();

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      await dispatch({ type: "LOGOUT" });
      navigate("/");
      if (open) {
        onClose?.();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePathChange = (path) => {
    if (open) {
      navigate(path);
      onClose?.();
    }
  };

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={!lgUp && open}
      PaperProps={{ sx: { width: 256 } }}
      sx={{
        zIndex: (theme) => theme.zIndex.appBar + 100,
      }}
      variant="temporary"
    >
      {!user && (
        <Box sx={{ p: 2 }}>
          <MainSidebarLink
            onClick={() => handlePathChange("/trainers")}
            color="textSecondary"
            underline="none"
            variant="subtitle2"
          >
            Find a Cubing Class
          </MainSidebarLink>
          <MainSidebarLink
            onClick={() => handlePathChange("/join-us")}
            color="textSecondary"
            underline="none"
            variant="subtitle2"
          >
            Join as a Trainer
          </MainSidebarLink>
          <Button
            onClick={() => handlePathChange("/login")}
            component="a"
            fullWidth
            sx={{ mt: 1.5 }}
            target="_blank"
            variant="contained"
          >
            Login
          </Button>
        </Box>
      )}
      {user?.userType == "student" && (
        <Box sx={{ p: 2 }}>
          <MainSidebarLink
            onClick={() => handlePathChange("/lessons")}
            color="textSecondary"
            underline="none"
            variant="subtitle2"
          >
            Home
          </MainSidebarLink>
          <MainSidebarLink
            onClick={() => handlePathChange("/trainers")}
            color="textSecondary"
            underline="none"
            variant="subtitle2"
          >
            Cubing Class
          </MainSidebarLink>
          <MainSidebarLink
            onClick={() => handlePathChange("/events")}
            color="textSecondary"
            underline="none"
            variant="subtitle2"
          >
            Events
          </MainSidebarLink>
          <MainSidebarLink
            onClick={() => handlePathChange("/profile")}
            color="textSecondary"
            underline="none"
            variant="subtitle2"
          >
            Profile
          </MainSidebarLink>
          <Button
            onClick={handleLogout}
            component="a"
            fullWidth
            sx={{ mt: 1.5 }}
            target="_blank"
            variant="contained"
          >
            Logout
          </Button>
        </Box>
      )}
      {user?.userType == "trainer" && (
        <Box sx={{ p: 2 }}>
          <MainSidebarLink
            onClick={() => handlePathChange("/lessons")}
            color="textSecondary"
            underline="none"
            variant="subtitle2"
          >
            Home
          </MainSidebarLink>
          <MainSidebarLink
            onClick={() => handlePathChange("/events")}
            color="textSecondary"
            underline="none"
            variant="subtitle2"
          >
            Events
          </MainSidebarLink>
          <MainSidebarLink
            onClick={() => handlePathChange("/profile")}
            color="textSecondary"
            underline="none"
            variant="subtitle2"
          >
            Profile
          </MainSidebarLink>
          <Button
            onClick={handleLogout}
            component="a"
            fullWidth
            sx={{ mt: 1.5 }}
            target="_blank"
            variant="contained"
          >
            Logout
          </Button>
        </Box>
      )}
    </Drawer>
  );
};
