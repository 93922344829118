import Grid from "@mui/material/Unstable_Grid2";
import ProfileBio from "./profile-bio";
import ProfileReviews from "./profile-review";
import ProfileTimeslotsOthers from "./profile-timeslots-others";

// ----------------------------------------------------------------------

export default function ProfileTabs({ trainer, availability, currentTab }) {
  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={7}>
        {currentTab == "profile" && <ProfileBio trainer={trainer} />}
        {currentTab == "reviews" && <ProfileReviews trainer={trainer} />}
      </Grid>

      <Grid xs={12} md={5}>
        <ProfileTimeslotsOthers trainer={trainer} />
      </Grid>
    </Grid>
  );
}
