import { useAuthContext } from "../hooks/useContext";
import { UserLessonsView } from "./user-lessons-view";
import { TrainerLessonsView } from "./trainer-lessons-view";
import { MainLayout } from "../components/main-layout";
import { AuthGuard } from "../authentication/auth-guard";

export default function LessonsView() {
  const { user } = useAuthContext();
  return (
    <AuthGuard>
      <MainLayout>
        {user?.userType === "student" && <UserLessonsView />}
        {user?.userType === "trainer" && <TrainerLessonsView />}
      </MainLayout>
    </AuthGuard>
  );
}
