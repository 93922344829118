import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import Mofunclass from "../assets/logo/Mofunclass.png";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useContext";
import { getInitials } from "../utils/get-initials";
import { useTheme } from "@emotion/react";
import CreditsIcon from "../assets/components/credits-icon.png";
import { Iconify } from "../utils/iconify";

export const MainNavbar = (props) => {
  const { onOpenSidebar } = props;
  const { user } = useAuthContext();

  const theme = useTheme();
  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "background.paper",
        borderBottomColor: "divider",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        color: "text.secondary",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          justifyContent: { xs: "center" },
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
          }}
        >
          <Link to="/" style={{ marginRight: "30px" }}>
            <img src={Mofunclass} alt="mofunland" width={150} />
          </Link>

          {!user && (
            <Box
              sx={{
                alignItems: "center",
                display: {
                  md: "flex",
                  xs: "none",
                },
              }}
            >
              <Link
                to="/trainers"
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  color="textSecondary"
                  underline="none"
                  variant="subtitle2"
                  sx={{ mr: 5 }}
                >
                  Find a Cubing Class
                </Typography>
              </Link>
              <Link
                to="/join-us"
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  color="text.secondary"
                  variant="subtitle2"
                  sx={{ mr: 5 }}
                >
                  Join as a Trainer
                </Typography>
              </Link>
            </Box>
          )}

          {user?.userType === "student" && (
            <Box
              sx={{
                alignItems: "center",
                display: {
                  md: "flex",
                  xs: "none",
                },
              }}
            >
              <Link
                to="/lessons"
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  color="textSecondary"
                  underline="none"
                  variant="subtitle2"
                  sx={{ mr: 5 }}
                >
                  My Lessons
                </Typography>
              </Link>
              <Link
                to="/trainers"
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  color="text.secondary"
                  variant="subtitle2"
                  sx={{ mr: 5 }}
                >
                  Cubing Class
                </Typography>
              </Link>
              <Link
                to="/events"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography
                  color="text.secondary"
                  variant="subtitle2"
                  sx={{ mr: 5 }}
                >
                  Events
                </Typography>
              </Link>
            </Box>
          )}

          {user?.userType === "trainer" && (
            <Box
              sx={{
                alignItems: "center",
                display: {
                  md: "flex",
                  xs: "none",
                },
              }}
            >
              <Link
                to="/lessons"
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  color="textSecondary"
                  underline="none"
                  variant="subtitle2"
                  sx={{ mr: 5 }}
                >
                  My Lessons
                </Typography>
              </Link>
              <Link
                to="/events"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography
                  color="text.secondary"
                  variant="subtitle2"
                  sx={{ mr: 5 }}
                >
                  Events
                </Typography>
              </Link>
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }} />

          <Box
            sx={{
              alignItems: "center",
            }}
          >
            {user?.userType === "student" && (
              <Box sx={{ display: "flex", gap: { md: 3 } }}>
                <Link to="/profile?tab=credits">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.secondary.light,
                      color: theme.palette.secondary.dark,
                      ":hover": {
                        backgroundColor: theme.palette.secondary.lighter,
                      },
                    }}
                    startIcon={
                      <img
                        src={CreditsIcon}
                        alt="credits icon"
                        style={{ width: 20 }}
                      />
                    }
                  >
                    {user.credits}
                  </Button>
                </Link>
                <Link to="/profile" style={{ textDecoration: "none" }}>
                  <Avatar
                    alt="profile"
                    src={user.photoUrl}
                    sx={{
                      backgroundColor: "#000",
                      display: {
                        xs: "none",
                        md: "inline-flex",
                      },
                    }}
                  >
                    {getInitials(user.name)}
                  </Avatar>
                </Link>
                <IconButton
                  color="inherit"
                  onClick={onOpenSidebar}
                  sx={{
                    display: {
                      sx: "inlibne",
                      md: "none",
                    },
                  }}
                >
                  <Iconify icon="ci:hamburger-md" width={24} />
                </IconButton>
              </Box>
            )}
            {user?.userType === "trainer" && (
              <>
                <Link to="/profile" style={{ textDecoration: "none" }}>
                  <Avatar
                    alt="profile"
                    src={user.photoUrl}
                    sx={{
                      backgroundColor: "#000",
                      display: {
                        xs: "none",
                        md: "inline-flex",
                      },
                    }}
                  >
                    {getInitials(user.name)}
                  </Avatar>
                </Link>
                <IconButton
                  color="inherit"
                  onClick={onOpenSidebar}
                  sx={{
                    display: {
                      sx: "inlibne",
                      md: "none",
                    },
                  }}
                >
                  <Iconify icon="ci:hamburger-md" width={24} />
                </IconButton>
              </>
            )}

            {!user && (
              <>
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                    gap: 2,
                  }}
                >
                  <Link to="/login">
                    <Button variant="contained">Login</Button>
                  </Link>
                </Box>
                <IconButton
                  color="inherit"
                  onClick={onOpenSidebar}
                  sx={{
                    display: {
                      sx: "inline",
                      md: "none",
                    },
                  }}
                >
                  <Iconify icon="ci:hamburger-md" width={24} />
                </IconButton>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
