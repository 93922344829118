import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";

const Tag = ({ label, bgcolor = "none" }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        border:
          bgcolor === "none"
            ? `1px solid ${theme.palette.neutral[300]}`
            : "none",
        py: 1,
        px: 2,
        borderRadius: "8px",
        bgcolor: bgcolor,
      }}
    >
      <Typography variant="subtitle2" color="text.primary">
        {label}
      </Typography>
    </Box>
  );
};

export default Tag;
