import {
  Box,
  Card,
  CardHeader,
  Container,
  Grid,
  Stack,
  Typography,
  alpha,
  CardActions,
  CardContent,
} from "@mui/material";
import { bgGradient } from "../utils/css";
import { useTheme } from "@emotion/react";
import Overlay from "../assets/background/overlay.png";
import ClassesImage from "../assets/images/classes-image.png";
import { useCollection } from "../hooks/useCollection";
import PendingClass from "../components/pending-class";
import { Link } from "react-router-dom";
import { Iconify } from "../utils/iconify";
import { useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useContext";
import supabase from "../supabase/config";
import { MainLayout } from "../components/main-layout";
import { AuthGuard } from "../authentication/auth-guard";

export const TrainerPendingLessonsView = () => {
  const theme = useTheme();
  const { user, authIsReady } = useAuthContext();
  const [documents, setDocuments] = useState(null);

  useEffect(() => {
    const fetchLessonsData = async () => {
      const { data, error } = await supabase.rpc("gettrainerlessons", {
        row_id: user.id,
      });
      if (data) {
        const docs = data.filter((d) => d.status == "pending");
        setDocuments(docs);
      }
    };
    fetchLessonsData();
  }, []);

  return (
    <AuthGuard>
      <MainLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Box
            sx={{
              ...bgGradient({
                color: alpha(
                  theme.palette.background.default,
                  theme.palette.mode === "light" ? 0.9 : 0.94
                ),
                imgUrl: Overlay,
              }),
              // backgroundColor: "blue",
              height: 350,
              position: "relative",
              mt: -8,
              mb: 8,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                right: 0,
                top: "15%",
              }}
            >
              <img src={ClassesImage} />
            </Box>
            <Container maxWidth="lg">
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}
              >
                <Box>
                  <Typography variant="h3" sx={{ mt: 3 }}>
                    Pending Classes
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 3 }}>
                    Check and approve your pending classes here
                  </Typography>
                </Box>
              </Box>
            </Container>
          </Box>

          <Container maxWidth="lg">
            <Stack spacing={3}>
              {documents?.map((lesson) => (
                <PendingClass lesson={lesson} key={lesson.id} stack={true} />
              ))}
            </Stack>
          </Container>
        </Box>
      </MainLayout>
    </AuthGuard>
  );
};
