import { Box, Button, Dialog, Typography } from "@mui/material";
import TrialPromotionImage from "../../assets/images/trial-promotion.png";
import AboutIcon from "../../assets/components/about-icon.png";

export const TrialPromotionDialog = ({ onClose, open, handleTryNextTime }) => {
  return (
    <Dialog
      onClose={onClose}
      open={!!open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "480px", // Set your width here
          },
        },
      }}
    >
      <img
        src={TrialPromotionImage}
        style={{ height: "100%" }}
        alt="trial promotion"
      />
      <Box
        sx={{
          py: "26px",
          px: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "26px",
          maxWidth: "480px",
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <img src={AboutIcon} height={24} alt="about" />
          <Typography variant="h6">Trial Class Promotion</Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="text.secondary">
            You currently have 30 credits from your referral!
            <br />
            <br />
            With an add-on of 30 credits ($30) you may gain access to a trial
            cubing class with one of our specialised instructor!
            <br />
            <br />
            Contact us to arrange today!
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: { xs: 2, md: 0 },
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Button
            variant="contained"
            sx={{ width: { xs: "100%", md: 210 } }}
            onClick={() =>
              window.open(
                "https://wa.me/88320702?text=Hello%2C%20I%20would%20like%20to%20book%20a%20trial%20class%20on%20Mofunclass"
              )
            }
          >
            Join Trial Class
          </Button>
          <Button
            variant="outlined"
            sx={{ width: { xs: "100%", md: 210 } }}
            onClick={handleTryNextTime}
          >
            Try it next time
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
