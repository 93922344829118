import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useContext";
import { useSnackbar } from "../components/snackbar";

export const AuthGuard = (props) => {
  const { children } = props;
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!user) {
      enqueueSnackbar("Login required");
      navigate("/login");
    }
  }, [user, navigate]);

  if (user) return <>{children}</>;
};
