import { Chip } from "@mui/material";

// ----------------------------------------------------------------------
const capitalize = (word) => {
  return word && word[0].toUpperCase() + word.slice(1);
};

const Label = ({ type }) => {
  const selectColor = (cType) => {
    switch (cType) {
      case "confirmed":
        return "#00A76F";
      case "cancelled":
        return "#B71D18";
      case "pending":
        return "#B76E00";
    }
  };

  const selectBackground = (bgType) => {
    switch (bgType) {
      case "confirmed":
        return "rgba(0, 167, 111, 0.16)";
      case "cancelled":
        return "rgba(183, 29, 24, 0.16)";
      case "pending":
        return "rgba(183, 110, 0, 0.16)";
    }
  };

  return (
    <Chip
      label={capitalize(type)}
      style={{
        width: "fit-content",
        color: selectColor(type),
        backgroundColor: selectBackground(type),
        borderRadius: "8px",
      }}
    />
  );
};

export default Label;
