import { Navigate, Route, Routes } from "react-router-dom";
import { TrainersView } from "./pages/trainers-view";
import { TrainerProfileView } from "./pages/trainer-profile-view";
import UserProfileView from "./pages/profile-view";
import LoginView from "./pages/login-view";
import { mainLayout } from "./utils/routes";
import { TrainerPendingLessonsView } from "./pages/trainer-pending-lessons-view";
import HomeView from "./pages/home-view";
import { useAuthContext } from "./hooks/useContext";
import EventsView from "./pages/events-view";
import PageNotFoundView from "./pages/misc/page-not-found-view";
import LessonsView from "./pages/lessons-view";
import RegisterView from "./pages/register-view";
import { JoinUsView } from "./pages/join-us-view";
import ForgetPasswordView from "./pages/forget_password-view";
import ResetPasswordView from "./pages/reset-password-view";
import VideoView from "./components/video/video-view";
const App = () => {
  const { isInitialized } = useAuthContext();
  return (
    isInitialized && (
      <Routes>
        <Route path="/" element={<HomeView />} />
        {/* <Route path="/" element={<VideoView />} /> */}
        <Route path="/login" element={<LoginView />} />
        <Route path="/forget-password" element={<ForgetPasswordView />} />
        <Route path="/reset-password" element={<ResetPasswordView />} />
        <Route path="/register" element={<RegisterView />} />
        <Route path="/trainers" element={<TrainersView />} />
        <Route path="/lessons" element={<LessonsView />} />
        <Route path="/profile" element={<UserProfileView />} />
        <Route
          path="/lessons/pending"
          element={<TrainerPendingLessonsView />}
        />
        <Route path="/trainers/profile/:id" element={<TrainerProfileView />} />
        <Route path="/events" element={<EventsView />} />
        <Route path="*" element={<PageNotFoundView />} />
        <Route path="/join-us" element={<JoinUsView />} />
      </Routes>
    )
  );
};

export default App;
