import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Pagination,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import SliderCube from "../../assets/components/slider-cube.png";
import { getRatingColor } from "../../utils/ratings";
import {
  formatTimestampDDMMMYYY,
  formatTimestampMMMMDDYYYY,
} from "../../utils/time";
import { differenceInYears, formatDistance } from "date-fns";
import { getInitials } from "../../utils/get-initials";
import { useEffect, useState } from "react";
import supabase from "../../supabase/config";

export default function ProfileReviews({ trainer }) {
  const [reviews, setReviews] = useState(null);

  useEffect(() => {
    const fetchReviewsData = async () => {
      const { data, error } = await supabase.from("reviews").select(`
      createdAt,
      review,
      users ( id, name, photoUrl, dateOfBirth )
    `);
      if (data) setReviews(data);
    };
    fetchReviewsData();
  }, []);

  const renderRatings = (
    <Card>
      <CardHeader title="Students' Ratings" />
      <Box sx={{ typography: "body2", p: 3 }}>
        <Grid container spacing={2}>
          {Object.entries(trainer.ratings).map(([attribute, score]) => (
            <Grid item sm={6} xs={12} key={attribute}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: 700 }}>
                    {attribute.toUpperCase()}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    {score}%
                  </Typography>
                </Box>
                <Slider
                  size="medium"
                  value={score}
                  // defaultValue={rating.score}
                  disabled
                  sx={{
                    "& .MuiSlider-thumb": {
                      backgroundImage: `url(${SliderCube})`,
                      width: 20,
                      height: 20,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundColor: "transparent",
                    },
                    "& .MuiSlider-track": {
                      backgroundColor: getRatingColor(attribute),
                    },
                    "& .MuiSlider-rail": {
                      backgroundColor: getRatingColor(attribute),
                    },
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Card>
  );

  const renderReviews = (
    <Card>
      <CardHeader title="What students are saying" />
      {reviews?.map((review) => (
        <Box sx={{ m: 3 }} key={review.users.id}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 3,
            }}
          >
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Avatar
                alt="profile"
                src={review.photoUrl}
                sx={{ backgroundColor: "#000" }}
              >
                {getInitials(review.users.name)}
              </Avatar>
              <Box>
                <Typography variant="subtitle1">{review.users.name}</Typography>
                <Typography variant="body2">
                  {differenceInYears(
                    new Date(),
                    new Date(review.users.dateOfBirth)
                  )}{" "}
                  years old
                </Typography>
              </Box>
            </Box>
            <Typography variant="body2">
              {formatTimestampMMMMDDYYYY(review.createdAt)}
            </Typography>
          </Box>
          <Typography variant="body2">{review.review}</Typography>
          <Divider sx={{ my: 5 }} />
        </Box>
      ))}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          m: 3,
        }}
      >
        {/* <Pagination
          count={10}
          shape="rounded"
          sx={{
            "& .MuiPaginationItem-root": {
              "&.Mui-selected": {
                background: "#00A76F",
                color: "#fff",
              },
            },
          }}
        /> */}
      </Box>
    </Card>
  );

  return (
    <Stack spacing={3}>
      {renderRatings}
      {renderReviews}
    </Stack>
  );
}
