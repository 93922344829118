import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "./theme";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import "react-multi-carousel/lib/styles.css";
import { AuthContextProvider } from "./context/AuthContext";
import SnackbarProvider from "./components/snackbar/snackbar-provider";
import LocalizationProvider from "./providers/localizationProvider";
import { LessonContextProvider } from "./context/LessonContext";

const settings = {
  theme: "light",
  responsiveFontSizes: true,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <LocalizationProvider>
      <ThemeProvider
        theme={createTheme({
          responsiveFontSizes: settings.responsiveFontSizes,
          mode: settings.theme,
        })}
      >
        <AuthContextProvider>
          <LessonContextProvider>
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </LessonContextProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </BrowserRouter>
);
