import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Iconify } from "../../utils/iconify";
import { useTheme } from "@emotion/react";

export const NoLessons = () => {
  const theme = useTheme();
  return (
    <Card>
      <Stack sx={{ py: "50px", textAlign: "center" }} spacing={2}>
        <Typography variant="h6">
          You do not have any upcoming classes
        </Typography>
        <Typography variant="body2">
          Contact us and we will recommend a suitable cube trainer for you.
          Otherwise, you may feel free to browse the available trainers.
        </Typography>
        <Box>
          <Link to="/trainers">
            <Button
              sx={{ mr: 2 }}
              variant="outlined"
              startIcon={<Iconify icon="fluent:people-16-filled" width={24} />}
            >
              View Trainers
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#00A76F",
                color: "#fff",
                ":hover": {
                  backgroundColor: theme.palette.secondary.dark,
                },
              }}
            >
              Contact Us
            </Button>
          </Link>
        </Box>
      </Stack>
    </Card>
  );
};
