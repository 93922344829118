import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Iconify } from "../utils/iconify";
import { useTheme } from "@emotion/react";
import AchievementIcon from "../assets/icons/Achievement.png";
import SessionIcon from "../assets/icons/SessionIcon.png";
import ExperienceIcon from "../assets/icons/ExperienceIcon.png";

export const Trainer = ({ trainer }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up("xs"));

  const formatCubingSpecialty = (cubingSpecialty) => {
    const combinedSpecialties = cubingSpecialty.join(", ");
    const displayText =
      combinedSpecialties.length > 20
        ? `${combinedSpecialties.slice(0, 20)}...`
        : combinedSpecialties;
    return displayText;
  };

  return (
    <Card>
      <Link to={`/trainers/profile/${trainer.id}`}>
        <Box
          sx={{
            position: "relative",
            paddingBottom: "100%",
            // m: 1.5,
          }}
        >
          <img
            src={trainer.photoUrl}
            alt="trainer photo"
            style={{
              position: "absolute",
              top: "0.5rem", // Add top padding
              left: "0.5rem", // Add left padding
              right: "0.5rem", // Add right padding
              bottom: "0.5rem", // Add bottom padding
              width: "calc(100% - 1rem)", // Adjust width to account for padding
              height: "calc(100% - 1rem)", // Adjust height to account for padding
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
        </Box>
      </Link>
      <CardContent sx={{ backgroundColor: "#fff", pt: 2, pb: 2 }}>
        <Typography gutterBottom variant="subtitle1" component="div">
          {trainer.name}
        </Typography>
        <Box sx={{ mb: 2, display: "flex", flexDirection: "column", gap: 1 }}>
          <Chip
            label={trainer.achievement}
            icon={<img src={AchievementIcon} width={14} />}
            style={{
              width: "fit-content",
              color: "#006C9C",
              backgroundColor: "rgba(0,184,217,0.16)",
              borderRadius: "8px",
              marginBottom: "5px",
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Iconify
                  icon="bxs:cube"
                  width={20}
                  sx={{ mr: 0.5 }}
                  color="text.secondary"
                />
                <Typography color="text.secondary" variant="caption">
                  {trainer.cubingExperience} years
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ mr: 0.5 }}>
                  <img src={ExperienceIcon} width={16} alt="Experience Icon" />
                </Box>
                <Typography color="text.secondary" variant="caption">
                  {trainer.teachingExperience} years
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Iconify
              icon="octicon:thumbsup-16"
              width={15}
              sx={{ ml: 0.4, mr: 1 }}
              color="text.secondary"
            />

            <Typography color="text.secondary" variant="caption">
              {formatCubingSpecialty(trainer.cubingSpecialty)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={SessionIcon} width={20} style={{ marginRight: "5px" }} />
            <Typography color="text.secondary" variant="caption">
              {trainer.completedLessons} sessions
            </Typography>
          </Box>
        </Box>
        {/* {trainer.briefDescription && (
          <Typography variant="caption" color="text.secondary">
            {trainer.briefDescription.substring(0, isXs ? 100 : 70).trim()}...
          </Typography>
        )} */}
      </CardContent>
      <CardActions
        sx={{
          mb: { xs: 2, md: 1 },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link to={`/trainers/profile/${trainer.id}`} style={{ width: "100%" }}>
          <Button size="medium" variant="outlined" fullWidth>
            View Profile
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};
