import { Fab, Tooltip } from "@mui/material";
import { Iconify } from "../../utils/iconify";

export const ContactUs = () => {
  return (
    <Tooltip title="Contact us">
      <Fab
        onClick={() =>
          window.open(
            "https://wa.me/6588320702?text=Hello%2C%20I%20have%20a%20question%20regarding%20Mofunclass"
          )
        }
        size="large"
        sx={{
          backgroundColor: "#25d366",
          bottom: 0,
          margin: (theme) => theme.spacing(4),
          position: "fixed",
          right: 0,
          zIndex: 1900,
          ":hover": {
            backgroundColor: "#21be5c",
          },
        }}
      >
        <Iconify icon="ic:baseline-whatsapp" width={30} color="#fff" />
      </Fab>
    </Tooltip>
  );
};
