import HomeAboutUs from "../components/home/home-about";
import HomeCurriculum from "../components/home/home-curriculum";
import HomeFooter from "../components/home/home-footer";
import HomeHero from "../components/home/home-hero";
import HomePricing from "../components/home/home-pricing";
import HomeTrainers from "../components/home/home-trainers";
import { MainLayout } from "../components/main-layout";

export default function HomeView() {
  const scrollToSection = () => {
    const section = document.getElementById("about-section");
    section.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <MainLayout>
      <HomeHero scrollToSection={scrollToSection} />
      <HomeAboutUs />
      <HomeCurriculum />
      <HomeTrainers />
      <HomePricing />
      <HomeFooter />
    </MainLayout>
  );
}
