import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Iconify } from "../../utils/iconify";
import { useCollection } from "../../hooks/useCollection";
import { formatTimestampEEEDDMMMYYY } from "../../utils/time";
import AboutIcon from "../../assets/components/about-icon.png";
import { useEffect, useState } from "react";
import supabase from "../../supabase/config";
import { useAuthContext } from "../../hooks/useContext";

export default function ProfileCredits() {
  const { user } = useAuthContext();
  const [documents, setDocuments] = useState(null);

  useEffect(() => {
    const fetchLessonsData = async () => {
      const { data, error } = await supabase
        .from("lessons")
        .select("*")
        .eq("studentId", user.id)
        .eq("status", "completed")
        .order("actualEndTime", { ascending: false });
      if (data) setDocuments(data);
    };
    fetchLessonsData();
  }, []);

  return (
    <Grid item xs={12} md={8}>
      <Stack gap={3}>
        <Card sx={{ p: 3 }}>
          <CardHeader
            avatar={<img src={AboutIcon} height={32} alt="cube icon" />}
            title="Credits Balance"
            action={
              <Button variant="contained" disabled>
                Top Up
              </Button>
            }
          />
          <Box sx={{ display: "flex", ml: 3 }}>
            <Typography variant="h3" sx={{ color: "#00A76F", mr: 1 }}>
              {user.credits}
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 4 }}>
              credits
            </Typography>
          </Box>
        </Card>
        <Card sx={{ p: 3 }}>
          <CardHeader title="Transaction History" />
          <Box
            sx={{
              display: "flex",
              ml: 3,
              mb: 1,
              flexDirection: "column",
              gap: 3,
            }}
          >
            {documents?.map((tx) => (
              <Box
                key={tx.id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="subtitle1">Cubing Class</Typography>
                  <Typography variant="body2">
                    {formatTimestampEEEDDMMMYYY(tx.actualEndTime)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Typography variant="subtitle1">
                    {/* {t.type == "Top Up" ? "$" : "-"} {t.amount}{" "}
                    {t.type == "Cubing Class" && "credits"} */}
                    {tx.creditsRequired} credits
                  </Typography>
                  <Typography variant="subtitle1">
                    <a href="/">PDF</a>
                  </Typography>
                </Box>
              </Box>
            ))}
            <Divider />
            {documents?.length > 5 && (
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Iconify icon="icon-park-outline:down" width={20} />
                <Typography variant="subtitle2">Show More</Typography>
              </Box>
            )}
          </Box>
        </Card>
      </Stack>
    </Grid>
  );
}
