import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Iconify } from "../../utils/iconify";
import { useAuthContext } from "../../hooks/useContext";

export default function ProfileGeneral() {
  const { user } = useAuthContext();

  return (
    <Grid item xs={12} md={8}>
      {user.userType === "trainer" && (
        <Card sx={{ mb: 3 }}>
          <CardHeader title="Teaching Experience" />
          <Box sx={{ display: "flex", gap: 5 }}>
            <Box sx={{ display: "flex", gap: 2, p: 3 }}>
              <Box
                sx={{
                  borderRadius: "8px",
                  height: 56,
                  width: 56,
                  backgroundColor: "rgba(0, 184, 217, 0.08)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Iconify
                  icon="solar:clock-circle-bold"
                  width={24}
                  color="#00B8D9"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2">
                  <b>{user.minutesCompleted}</b>
                </Typography>
                <Typography variant="caption">
                  Online teaching minutes
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 2, p: 3 }}>
              <Box
                sx={{
                  borderRadius: "8px",
                  height: 56,
                  width: 56,
                  backgroundColor: "rgba(0, 167, 111, 0.12)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Iconify icon="mdi:tick-decagram" width={24} color="#00A76F" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2">
                  <b>{user.completedLessons}</b>
                </Typography>
                <Typography variant="caption">Completed Lessons</Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      )}
      <Card sx={{ p: 3 }}>
        <TextField
          name="displayName"
          label="Name"
          fullWidth
          sx={{ mb: 3 }}
          value={user.name}
          disabled
        />
        <TextField
          name="email"
          label="Email Address"
          fullWidth
          value={user.email}
          disabled
          sx={{ mb: 3 }}
        />
        <Stack alignItems="flex-end">
          <Button variant="outlined" disabled>
            edit
          </Button>
        </Stack>
      </Card>
    </Grid>
  );
}
