import { createContext, useReducer, useEffect, useState } from "react";
import supabase from "../supabase/config";

export const AuthContext = createContext();

const ActionType = {
  INITIALIZE: "INITIALIZE",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  REGISTER: "REGISTER",
};

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case ActionType.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case ActionType.LOGOUT:
      return { ...state, isAuthenticated: false, user: null };
    case ActionType.INITIALIZE:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
        isInitialized: true,
      };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const { data } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log(event, session);
      if (session) {
        const { data, error } = await supabase
          .from("users")
          .select()
          .eq("id", session.user.id);
        const payload = {
          user: { ...session.user, ...data[0] },
          isAuthenticated: true,
        };
        dispatch({
          type: ActionType.INITIALIZE,
          payload: payload,
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    });

    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  const login = async ({ email, password }) => {
    const {
      // data: { user },
      error: signInError,
    } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (signInError) throw signInError;
    // const { data: userData, error: usersError } = await supabase
    //   .from("users")
    //   .select("*")
    //   .eq("id", user.id);
    // if (usersError) throw usersError;
    // dispatch({
    //   type: ActionType.LOGIN,
    //   payload: { ...user, user: { ...userData[0] } },
    // });
  };

  const logout = async () => {
    const { error: logoutError } = await supabase.auth.signOut();
    if (logoutError) throw logoutError;
    dispatch({ type: ActionType.LOGOUT });
  };

  return (
    <AuthContext.Provider value={{ ...state, dispatch, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
