import { Box, Button, Divider, Typography } from "@mui/material";
import Cube from "../../assets/images/cube-image.png";

export const PricingPlan = ({ pricingPlan }) => {
  const {
    cta,
    currency,
    description,
    features,
    image,
    name,
    popular,
    price,
    color,
    sx,
    ...other
  } = pricingPlan;

  return (
    <Box>
      <Box
        sx={{
          background: color,
          py: 1,
          mb: 3,
          borderRadius: 1.5,
          color: "#fff",
        }}
      >
        <Typography sx={{ textAlign: "center" }} variant="h6">
          {name.toUpperCase()}
        </Typography>
      </Box>
      <Box
        sx={{
          borderRadius: 3,
          border: "1px solid #000",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          overflow: "hidden",

          ...sx,
        }}
        {...other}
      >
        {popular && (
          <Box
            sx={{
              position: "absolute",
              right: -50,
              top: 15,
              background: "#000",
              color: "#fff",
              padding: "3px 50px",
              transform: "rotate(45deg)",
              zIndex: 2,
            }}
          >
            <Typography variant="subtitle1">Popular</Typography>
          </Box>
        )}
        <Box sx={{ p: 3 }}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h4">
              {currency}
              {price}
            </Typography>
            <Typography
              color="textSecondary"
              sx={{
                alignSelf: "flex-end",
                ml: 1,
              }}
              variant="subtitle2"
            >
              /package
            </Typography>
          </Box>

          <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
            {description}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            p: 3,
          }}
        >
          {features.map((feature) => (
            <Box
              key={feature}
              sx={{
                alignItems: "center",
                display: "flex",
                "& + &": {
                  mt: 2,
                },
              }}
            >
              {/* <CheckIcon fontSize="small" sx={{ color: "text.primary" }} /> */}
              {feature ? (
                <img src={Cube} alt="Cube image" width={25} />
              ) : (
                <Box sx={{ height: 25 }}></Box>
              )}
              <Typography
                sx={{
                  fontWeight: 500,
                  ml: 2,
                }}
                variant="body2"
              >
                {feature}
              </Typography>
            </Box>
          ))}
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button fullWidth variant={popular ? "contained" : "outlined"}>
              Buy Credits
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
