import { Typography } from "@mui/material";
import { format } from "date-fns";
import { Timestamp } from "firebase/firestore";

const currentDateTime = new Date();

export const getStartTimestamp = () => {
  return Timestamp.fromDate(currentDateTime);
};

export const getEndTimestamp = () => {
  const futureDateTime = new Date(currentDateTime);
  futureDateTime.setDate(currentDateTime.getDate() + 14);
  return Timestamp.fromDate(futureDateTime);
};

export const formatTimestampDDMMM = (date) => {
  const jsDate = new Date(date);
  const jsxElement = (
    <>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {format(jsDate, "eee")}
        <br />
        {format(jsDate, "dd MMM")}
      </Typography>

      {format(jsDate, "h:mm a")}
    </>
  );
  return jsxElement;
};

export const formatTimestampDDMMMYYY = (date) => {
  const jsDate = new Date(date);
  return format(jsDate, "dd MMM YYY");
};

export const getTimeFromTimestamp = (date) => {
  const jsDate = new Date(date);
  return format(jsDate, "h:mm a");
};

export const formatTimestampEEEDDMMMYYY = (date) => {
  const jsDate = new Date(date);
  return format(jsDate, "EEE, dd MMM yyyy");
};

export const formatTimestampMMMMDDYYYY = (date) => {
  const jsDate = new Date(date);
  return format(jsDate, "MMMM dd, yyyy");
};

export const formatTimestampEEEDDMMMHMMA = (date) => {
  const jsDate = new Date(date);
  return format(jsDate, "EEE dd MMM, h:mm a");
};
