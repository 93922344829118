import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import supabase from "../supabase/config";
import RHFTextField from "../components/hook-form/rhf-text-field";
import { Iconify } from "../utils/iconify";
import FormProvider from "../components/hook-form/form-provider";
import { Container, Link } from "@mui/material";
import { useSnackbar } from "../components/snackbar";
import EmailInBoxIcon from "../assets/icons/EmailInBoxIcon";
import { GuestGuard } from "../authentication/guest-guard";

export default function ForgetPasswordView() {
  const { enqueueSnackbar } = useSnackbar();
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
  });

  const defaultValues = {
    email: "",
  };

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { error } = await supabase.rpc("reset_password", {
        p_email: data.email,
      });
      if (error) throw error;
      else enqueueSnackbar("Password reset link sent");
      reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      reset();
    }
  });

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <RHFTextField name="email" label="Email address" />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Send Request
      </LoadingButton>

      <Link
        href="/"
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: "center",
          display: "inline-flex",
          textDecoration: "none !important",
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Return Home
      </Link>
    </Stack>
  );

  const renderHead = (
    <>
      <EmailInBoxIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ my: 5, textAlign: "center" }}>
        <Typography variant="h3">Forgot your password?</Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Please enter the email address associated with your account and we
          will email you a link to reset your password.
        </Typography>
      </Stack>
    </>
  );

  return (
    <GuestGuard>
      <Container maxWidth="xs" sx={{ mt: 15 }}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          {renderHead}

          {renderForm}
        </FormProvider>
      </Container>
    </GuestGuard>
  );
}
