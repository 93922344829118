export const getRatingColor = (attribute) => {
  switch (attribute) {
    case "Teaching Skills":
      return "#212B36";
    case "Punctuality":
      return "#FFAB00";
    case "Cubing Knowledge":
      return "#00A76F";
    case "Cubing Skills":
      return "#00B8D9";
  }
};
