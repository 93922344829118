import {
  Card,
  CardHeader,
  Box,
  Stack,
  Typography,
  Divider,
  Button,
  useMediaQuery,
  useTheme,
  Tooltip,
  Fab,
} from "@mui/material";
import { Iconify } from "../../utils/iconify";
import Tag from "../common/tag";
export default function ProfileBio({ trainer }) {
  // console.log(user);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("lg"));

  const listCategories = (categories) => {
    const updatedCateogories = categories.slice(1);
    return updatedCateogories.join(", ");
  };

  const renderAbout = (
    <Card>
      <CardHeader title="About" />
      <Box sx={{ typography: "body2", p: 3 }}>{trainer.briefDescription}</Box>
    </Card>
  );

  const renderBackground = (
    <Card>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Iconify icon="bxs:cube" width={24} sx={{ mr: 2 }} />
            <Typography variant="body2">Cubing Experience</Typography>
          </Box>
          <Typography variant="body2">
            {trainer.cubingExperience} years
          </Typography>
        </Box>
        <Divider />
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Iconify
                icon="material-symbols:language"
                width={24}
                sx={{ mr: 2 }}
              />
              <Typography variant="body2">Fluent in</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              {trainer.languagesSpoken.map((language) => (
                <Tag label={language} key={language} />
              ))}
            </Box>
          </Box>
        </Stack>
        <Divider />

        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Iconify icon="octicon:thumbsup-16" width={20} sx={{ mr: 2 }} />
              <Typography variant="body2">Specialty</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              {isXs ? (
                <>
                  <Tag label={trainer.cubingSpecialty[0]} />
                  <Tooltip title={listCategories(trainer.cubingSpecialty)}>
                    <Button sx={{ bgcolor: "rgba(145, 158, 171, 0.08)" }}>
                      {`+${trainer.cubingSpecialty.length - 1}`}
                    </Button>
                  </Tooltip>
                </>
              ) : (
                trainer.cubingSpecialty.map((speciality) => (
                  <Tag label={speciality} key={speciality} />
                ))
              )}
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Card>
  );

  const renderTeachingExperience = (
    <Card>
      <CardHeader title="Teaching Experience" />
      <Box
        sx={{
          display: "flex",
          gap: { xs: 0, md: 5 },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ display: "flex", gap: 2, px: 3, py: { xs: 1, md: 3 } }}>
          <Box
            sx={{
              borderRadius: "8px",
              height: 56,
              width: 56,
              backgroundColor: "rgba(0, 184, 217, 0.08)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Iconify
              icon="solar:clock-circle-bold"
              width={24}
              color="#00B8D9"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="body2">
              <b>{trainer.minutesCompleted}</b>
            </Typography>
            <Typography variant="caption">Online teaching minutes</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2, px: 3, py: { xs: 2, md: 3 } }}>
          <Box
            sx={{
              borderRadius: "8px",
              height: 56,
              width: 56,
              backgroundColor: "rgba(0, 167, 111, 0.12)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Iconify icon="mdi:tick-decagram" width={24} color="#00A76F" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="body2">
              <b>{trainer.completedLessons}</b>
            </Typography>
            <Typography variant="caption">Completed Lessons</Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );

  return (
    <Stack spacing={3}>
      {renderAbout}
      {renderBackground}
      {renderTeachingExperience}
    </Stack>
  );
}
