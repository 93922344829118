import { Card, Stack, Typography } from "@mui/material";

export const NoLessonFilter = ({ tab }) => (
  <Card>
    <Stack sx={{ py: "50px", textAlign: "center" }} spacing={2}>
      <Typography variant="h6">No {tab} classes</Typography>
      <Typography variant="body2">
        All your {tab} classes will appear here
      </Typography>
    </Stack>
  </Card>
);
