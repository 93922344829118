import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { Iconify } from "../../utils/iconify";
import {
  Typography,
  CardMedia,
  CardContent,
  IconButton,
  Stack,
  Button,
  Divider,
  Grid,
} from "@mui/material";
import Carousel from "react-multi-carousel";
import { useEffect, useState } from "react";
import "../../index.css";
import DialogBookLesson from "../booking/dialog-book-lesson";
import {
  formatTimestampDDMMM,
  formatTimestampEEEDDMMMHMMA,
} from "../../utils/time";
import { useTheme } from "@emotion/react";
import { carouselResponsive } from "../../utils/carousel";
import DialogBookingConfimed from "../booking/dialog-booking-confirmed";
import DialogBookingStep from "../booking/dialog-booking-step";
import { useAuthContext } from "../../hooks/useContext";
import { useNavigate } from "react-router-dom";
import supabase from "../../supabase/config";
import { addDays, format } from "date-fns";

export default function ProfileTimeslotsOthers({ trainer }) {
  const theme = useTheme();
  const { user, isAuthReady } = useAuthContext();
  const [carouselRefTrainer, setCarouselRefTrainer] = useState(null);
  const [carouselRefTimeslot, setCarouselTimeslot] = useState(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [activeDate, setActiveDate] = useState(null);
  const [activeTime, setActiveTime] = useState(null);
  const [selDate, setSelDate] = useState(null);

  const [availability, setAvailability] = useState(null);
  const [recommendedTrainers, setRecommendedTrainers] = useState(null);
  const [activeDatetime, setActiveDatetime] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAvailability = async () => {
      const startDate = new Date();
      const endDate = addDays(startDate, 14);
      const { data, error } = await supabase
        .from("availability")
        .select("*")
        // .order("startDate", { ascending: true })
        .eq("trainerId", trainer.id)
        .is("reservedBy", null)
        .order("startTime", { ascending: true })
        .range(startDate.toISOString(), endDate.toISOString(), {
          column: "startTime",
        });

      if (data) setAvailability(data);
    };
    fetchAvailability();
  }, []);

  useEffect(() => {
    const fetchRecommendedTrainers = async () => {
      const { data, error } = await supabase
        .from("users")
        .select("*")
        .in("id", trainer.recommendedTrainers);
      if (data) setRecommendedTrainers(data);
    };
    fetchRecommendedTrainers();
  }, []);

  const handleSelectDatetime = (id) => {
    const selectedDatetimeObj = availability.filter((av) => av.id == id);
    setActiveDatetime(selectedDatetimeObj[0]);
    console.log(selectedDatetimeObj[0].startTime);
  };

  const nextSlideTrainer = () => {
    if (carouselRefTrainer) {
      carouselRefTrainer.next();
    }
  };

  const prevSlideTrainer = () => {
    if (carouselRefTrainer) {
      carouselRefTrainer.previous();
    }
  };

  const nextSlideTimeslot = () => {
    if (carouselRefTimeslot) {
      carouselRefTimeslot.next();
    }
  };

  const prevSlideTimeslot = () => {
    if (carouselRefTimeslot) {
      carouselRefTimeslot.previous();
    }
  };

  const handleOpenDialog = () => {
    if (!user) {
      navigate("/login");
    }
    setIsOpenDialog(true);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      // Set 'open' to false, however you would do that with your particular code.
      setIsOpenDialog(false);
    }
  };

  const renderOtherTrainers = recommendedTrainers && (
    <Card sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
      <CardHeader
        title="Other cubing trainers"
        action={
          <Box sx={{ display: "flex", gap: 2 }}>
            <IconButton
              onClick={prevSlideTrainer}
              variant="outlined"
              color="primary"
              sx={{
                borderRadius: "50%",
                border: "1px solid rgba(145, 158, 171, 0.32)",
              }}
            >
              <Iconify icon="ep:arrow-left" width={24} />
            </IconButton>
            <IconButton
              onClick={nextSlideTrainer}
              variant="outlined"
              color="primary"
              sx={{
                borderRadius: "50%",
                border: "1px solid rgba(145, 158, 171, 0.32)",
              }}
            >
              <Iconify icon="ep:arrow-right" width={24} />
            </IconButton>
          </Box>
        }
      />
      <Box
        sx={{
          position: "relative",
          m: 3,
          mt: 0,
        }}
      >
        <Carousel
          responsive={carouselResponsive.trainers}
          ref={(el) => setCarouselRefTrainer(el)}
          infinite={false}
          removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
          partialVisible
          className="react-multi-carousel-list"
        >
          {recommendedTrainers.map((trainer) => (
            <Card
              sx={{
                mr: 3,
              }}
              key={trainer.id}
            >
              <Link to="/">
                <CardMedia
                  image={trainer.photoUrl}
                  sx={{
                    height: { xs: 200, md: 150 },
                    width: { xs: 230, md: "100%" },
                    borderRadius: "5px",
                    position: "relative",
                  }}
                />
              </Link>
              <CardContent sx={{ backgroundColor: "#fff" }}>
                <Typography gutterBottom variant="h6" component="div">
                  {trainer.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Iconify icon="bxs:cube" width={24} color="text.secondary" />
                  <Typography variant="body2" color="text.secondary">
                    {trainer.teachingExperience} years
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Carousel>
      </Box>
    </Card>
  );

  const renderAvailability = availability && (
    <Card>
      <CardHeader
        title="Availability"
        subheader="Book a cubing class by selecting a date and time slot"
      />
      <Box sx={{ mx: 3, mb: 3 }}>
        {/* <Box sx={{ display: "flex", mb: 3, gap: 2, alignItems: "center" }}> */}
        <Grid container sx={{ alignItems: "center" }} spacing={1}>
          <Grid item md={11} sm={6} xs={12} sx={{ overflow: "hidden" }}>
            {availability.length < 4 && (
              <Box sx={{ display: "flex", gap: 2 }}>
                {availability.map((av) => (
                  <Button
                    variant="outlined"
                    sx={{
                      display: "block",
                      border:
                        activeDatetime == av
                          ? "1px solid #212B36"
                          : "1px solid rgba(145, 158,171,0.32)",
                    }}
                    key={av.id}
                    onClick={() => handleSelectDatetime(av.id)}
                  >
                    {formatTimestampDDMMM(av.startTime)}
                  </Button>
                ))}
              </Box>
            )}
            {availability.length > 3 && (
              <Carousel
                responsive={carouselResponsive.date}
                ref={(el) => setCarouselTimeslot(el)}
                infinite={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                partialVisible
                className="react-multi-carousel-list"
              >
                {availability?.map((av) => (
                  <Button
                    variant="outlined"
                    sx={{
                      display: "block",
                      border:
                        activeDatetime == av
                          ? "1px solid #212B36"
                          : "1px solid rgba(145, 158,171,0.32)",
                    }}
                    key={av.id}
                    onClick={() => handleSelectDatetime(av.id)}
                  >
                    {formatTimestampDDMMM(av.date)}
                  </Button>
                ))}
              </Carousel>
            )}
          </Grid>
          {availability.length >= 3 && (
            <Grid item md={1} sm={6} xs={12}>
              <IconButton
                onClick={nextSlideTimeslot}
                variant="outlined"
                color="primary"
                sx={{
                  borderRadius: "50%",
                  backgroundColor: theme.palette.neutral[200],
                  ":hover": {
                    backgroundColor: theme.palette.neutral[300],
                  },
                }}
              >
                <Iconify icon="ep:arrow-right" width={24} />
              </IconButton>
            </Grid>
          )}
        </Grid>
        {activeDatetime && (
          <Button
            fullWidth
            sx={{
              mt: 3,
              backgroundColor: "#00A76F",
              color: "#fff",
              ":hover": {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
            onClick={handleOpenDialog}
          >
            {`Book class for ${formatTimestampEEEDDMMMHMMA(
              activeDatetime.startTime
            )}`}
          </Button>
        )}
      </Box>
    </Card>
  );

  return (
    <>
      <DialogBookingStep
        onClose={handleCloseDialog}
        open={isOpenDialog}
        timeslot={activeDatetime}
        trainer={trainer}
      />
      <Stack spacing={3}>
        {renderAvailability}
        {renderOtherTrainers}
      </Stack>
    </>
  );
}
