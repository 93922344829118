import { createContext, useReducer, useEffect, useState } from "react";
import supabase from "../supabase/config";
import { useAuthContext } from "../hooks/useContext";
import { differenceInMinutes } from "date-fns";

export const LessonContext = createContext();

const ActionType = {
  INITIALIZE: "INITIALIZE",
  UPDATE_LESSON: "UPDATE_LESSON",
  CANCEL_LESSON: "CANCEL_LESSON",
};

const initialState = {
  lessons: [],
};
export const lessonReducer = (state, action) => {
  switch (action.type) {
    case ActionType.INITIALIZE:
      return {
        ...state,
        lessons: action.payload.lessons,
      };
    case ActionType.UPDATE_LESSON:
      return {
        ...state,
        lessons: action.payload.lessons,
      };
    case ActionType.CANCEL_LESSON:
      return {
        ...state,
        lessons: action.payload.lessons,
      };
    default:
      return state;
  }
};

export const LessonContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(lessonReducer, initialState);
  const { user } = useAuthContext();

  useEffect(() => {
    const initialize = async () => {
      if (!user) {
        return;
      }

      const { data, error } = await supabase.rpc("gettrainerlessons", {
        row_id: user.id,
      });
      dispatch({
        type: ActionType.INITIALIZE,
        payload: { lessons: data },
      });
    };
    initialize();
  }, [dispatch, user]);

  const approveLesson = async ({ id, studentId, availabilityId }) => {
    const { error: lessonsError } = await supabase
      .from("lessons")
      .update({
        status: "confirmed",
      })
      .eq("id", id);

    if (lessonsError) throw "Something went wrong";

    const bookingData = {
      type: "confirmed",
      studentId: studentId,
      trainerId: user.id,
      availabilityId: availabilityId,
    };

    const { error: bookingsError } = await supabase
      .from("bookings")
      .insert(bookingData);

    if (bookingsError) throw "Something went wrong";
    const updatedLessons = state.lessons.map((lesson) => {
      if (lesson.id === id) {
        return {
          ...lesson,
          status: "confirmed",
        };
      } else {
        return lesson;
      }
    });

    dispatch({
      type: ActionType.CANCEL_LESSON,
      payload: { lessons: updatedLessons },
    });
  };

  const cancelLesson = async ({
    id,
    availabilityId,
    creditsRequired,
    studentId,
  }) => {
    if (availabilityId) {
      const { availabilityError } = await supabase
        .from("availability")
        .update({
          reservedBy: null,
        })
        .eq("id", availabilityId);
      if (availabilityError) throw "Something went wrong";

      const { error: usersError } = await supabase.rpc("increment", {
        x: creditsRequired,
        row_id: studentId,
      });
      if (usersError) throw "Something went wrong";
      const bookingData = {
        type: "cancelled",
        studentId: studentId,
        trainerId: user.id,
        availabilityId: availabilityId,
      };

      const { error: bookingsError } = await supabase
        .from("bookings")
        .insert(bookingData);

      if (bookingsError) throw "Something went wrong";
    }

    const { error: lessonsError } = await supabase
      .from("lessons")
      .update({
        status: "cancelled",
      })
      .eq("id", id);

    if (lessonsError) throw "Something went wrong";

    const updatedLessons = state.lessons.map((lesson) => {
      if (lesson.id === id) {
        return {
          ...lesson,
          status: "cancelled",
        };
      } else {
        return lesson;
      }
    });

    dispatch({
      type: ActionType.CANCEL_LESSON,
      payload: { lessons: updatedLessons },
    });
  };

  const endLesson = async ({
    id,
    actualStartTime,
    trainerId,
    creditsRequired,
    studentId,
  }) => {
    const actualEndTime = new Date();
    const { error: lessonsError } = await supabase
      .from("lessons")
      .update({
        status: "completed",
        actualEndTime,
        lessonStatus: "completed",
      })
      .eq("id", id);
    if (lessonsError) throw "Something went wrong";

    const minutesClocked = differenceInMinutes(
      actualEndTime,
      new Date(actualStartTime)
    );
    const { error: usersError } = await supabase.rpc("clockteachingminutes", {
      x: minutesClocked,
      row_id: trainerId,
    });
    if (usersError) throw "Something went wrong";

    const { error: creditsError } = await supabase.rpc("decrement", {
      x: creditsRequired,
      row_id: studentId,
    });
    if (creditsError) throw "Something went wrong";

    const updatedLessons = state.lessons.map((lesson) => {
      if (lesson.id === id) {
        return {
          ...lesson,
          actualEndTime,
          lessonStatus: "completed",
          status: "completed",
        };
      } else {
        return lesson;
      }
    });

    dispatch({
      type: ActionType.UPDATE_LESSON,
      payload: { lessons: updatedLessons },
    });
  };

  const startLesson = async ({ id }) => {
    const actualStartTime = new Date();
    const { error: lessonsError } = await supabase
      .from("lessons")
      .update({
        actualStartTime,
        lessonStatus: "started",
      })
      .eq("id", id);
    if (lessonsError) throw "Something went wrong";
    const updatedLessons = state.lessons.map((lesson) => {
      if (lesson.id === id) {
        return {
          ...lesson,
          actualStartTime,
          lessonStatus: "started",
        };
      } else {
        return lesson;
      }
    });
    dispatch({
      type: ActionType.UPDATE_LESSON,
      payload: { lessons: updatedLessons },
    });
  };

  return (
    <LessonContext.Provider
      value={{
        ...state,
        dispatch,
        startLesson,
        endLesson,
        cancelLesson,
        approveLesson,
      }}
    >
      {children}
    </LessonContext.Provider>
  );
};
