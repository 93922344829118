import * as Yup from "yup";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

// components
import Background from "../assets/sample/sample6.png";
import Overlay from "../assets/background/overlay_2.jpg";
import Mofunclass from "../assets/logo/Mofunclass.png";
import Image from "../assets/images/cube-image.png";
import {
  alpha,
  Alert,
  Button,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link } from "react-router-dom";
import { Iconify } from "../utils/iconify";
import { useTheme } from "@emotion/react";
import { bgGradient } from "../utils/css";
import FormProvider from "../components/hook-form/form-provider";
import { useForm } from "react-hook-form";
import RHFTextField from "../components/hook-form/rhf-text-field";
import { useBoolean } from "../components/hook-form/use-boolean";
import { useAuthContext } from "../hooks/useContext";
import supabase from "../supabase/config";
import { useSnackbar } from "../components/snackbar";
import { GuestGuard } from "../authentication/guest-guard";

// ----------------------------------------------------------------------

export default function RegisterView() {
  const theme = useTheme();
  const { dispatch } = useAuthContext();
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [referral, setReferral] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleVerify = async () => {
    try {
      setIsVerified(false);
      setReferral(null);
      setIsVerifying(true);
      setErrorMsg("");
      setSuccessMsg("");
      const { data, error } = await supabase
        .from("users")
        .select()
        .eq("referralCode", getValues("referralCode"));
      if (data.length === 0) {
        throw "Invalid referral code";
      }
      if (error) {
        throw error;
      }
      setTimeout(() => {
        setSuccessMsg("Enjoy 30 free credits on us!");
        setIsVerifying(false);
        setIsVerified(true);
        setReferral(data[0]);
      }, 1500);
    } catch (error) {
      setTimeout(() => {
        setValue("referralCode", "");
        setErrorMsg(error);
        setIsVerifying(false);
      }, 1500);
    }
  };

  const LoginSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    password: Yup.string().required("Password is required"),
  });

  const password = useBoolean();

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    referralCode: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    setErrorMsg("");
    let userData;
    try {
      if (data.referralCode !== "" && !isVerified) {
        throw "Please verify referral code";
      }
      const {
        data: { user },
        error: signUpError,
      } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
      });
      if (signUpError) {
        throw signUpError;
      }
      if (user) {
        userData = {
          id: user.id,
          credits: data.referralCode ? 30 : 0,
          name: `${data.firstName} ${data.lastName}`,
          email: data.email,
          userType: "student",
          referralCode: `MFC${user.id.slice(-5).toUpperCase()}`,
          eligableForTrialClass: isVerified,
        };

        const { error: usersError } = await supabase
          .from("users")
          .insert([userData]);
        if (usersError) {
          throw usersError;
        }
        if (isVerified) {
          const referralData = {
            referrerId: referral.id,
            referentId: user.id,
          };
          const { error: referralsError } = await supabase
            .from("referrals")
            .insert(referralData);
          if (referralsError) {
            throw referralsError;
          }
        }
      }
      dispatch({ type: "LOGIN", payload: { ...user, data: userData } });
      enqueueSnackbar("Welcome to Mofunclass!");
    } catch (error) {
      console.log(error);
      setErrorMsg("Something went wrong");
    }
  });

  const renderLogo = (
    <Box
      sx={{
        zIndex: 9,
        position: "absolute",
        m: { xs: 2, md: 5 },
      }}
    >
      <Link to="/">
        <img src={Mofunclass} width={150} />
      </Link>
    </Box>
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: "auto",
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        alignSelf: "center",
      }}
    >
      <Stack spacing={2} sx={{ mb: 5 }}>
        <Typography variant="h4">Create new account</Typography>
        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">Already have an account? </Typography>

          <Link to="/login" style={{ textDecoration: "none" }}>
            <Typography variant="subtitle2" sx={{ color: "#00A76F" }}>
              Login
            </Typography>
          </Link>
        </Stack>
      </Stack>

      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack spacing={2.5}>
          {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
          {!!successMsg && <Alert severity="success">{successMsg}</Alert>}
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <RHFTextField name="firstName" label="First name" />
            <RHFTextField name="lastName" label="Last name" />
          </Stack>
          <RHFTextField name="email" label="Email address" />

          <RHFTextField
            autoComplete="new-password"
            name="password"
            label="Password"
            type={password.value ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={password.onToggle} edge="end">
                    <Iconify
                      icon={
                        password.value
                          ? "solar:eye-bold"
                          : "solar:eye-closed-bold"
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <RHFTextField
            name="referralCode"
            label="Referral Code"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* <Button variant="outlined" onClick={handleVerify}>
                    Verify
                  </Button> */}
                  <LoadingButton
                    variant="outlined"
                    onClick={handleVerify}
                    loading={isVerifying}
                  >
                    Verify
                  </LoadingButton>
                </InputAdornment>
              ),
            }}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Create account
          </LoadingButton>
        </Stack>
      </FormProvider>
      <Typography
        component="div"
        sx={{
          color: "text.secondary",
          mt: 2.5,
          typography: "caption",
          textAlign: "center",
        }}
      >
        {"By signing up, I agree to "}
        <Link underline="always" color="text.primary">
          Terms of Service
        </Link>
        {" and "}
        <Link underline="always" color="text.primary">
          Privacy Policy
        </Link>
        .
      </Typography>
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      spacing={10}
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === "light" ? 0.88 : 0.94
          ),
          imgUrl: Overlay,
        }),
        display: { xs: "none", md: "inline-flex" },
      }}
    >
      <Box component="img" alt="auth" src={Image} sx={{ maxWidth: 450 }} />
    </Stack>
  );

  return (
    <GuestGuard>
      <Stack
        component="main"
        direction="row"
        sx={{
          height: "100vh",
        }}
      >
        {renderLogo}

        {renderSection}

        {renderContent}
      </Stack>
    </GuestGuard>
  );
}
