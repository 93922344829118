import { Box, Typography } from "@mui/material";
import ComingSoonImage from "../assets/images/coming-soon.png";
import { MainLayout } from "../components/main-layout";
import { AuthGuard } from "../authentication/auth-guard";

export default function EventsView() {
  return (
    <AuthGuard>
      <MainLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "calc(100vh - 110px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Typography variant="h3">Coming Soon!</Typography>
            <Typography variant="body1">
              We are currently working hard on this page!
            </Typography>
            <Box sx={{ mt: 5 }}>
              <img
                src={ComingSoonImage}
                alt="coming soon"
                style={{ width: 328 }}
              />
            </Box>
          </Box>
        </Box>
      </MainLayout>
    </AuthGuard>
  );
}
