import * as Yup from "yup";
import {
  Alert,
  Box,
  Container,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import JoinUsImage from "../assets/images/join-us.png";
import CoverImage from "../assets/background/overlay_4.png";
import FormProvider from "../components/hook-form/form-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import RHFTextField from "../components/hook-form/rhf-text-field";
import { useState } from "react";
import RHFAutocomplete from "../components/hook-form/rhf-autocomplete";
import { Iconify } from "../utils/iconify";
import { countries } from "../utils/data/countries";
import { RHFMultiCheckbox } from "../components/hook-form/rhf-multicheckbox";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSnackbar } from "../components/snackbar";

import { RHFSelect } from "../components/hook-form/rhf-select";
import supabase from "../supabase/config";
import { MainLayout } from "../components/main-layout";

const LANGUAGEOPTIONS = [
  { label: "English", value: "english" },
  { label: "Mandarin", value: "mandarin" },
];

const SPECIALTYOPTIONS = [
  { label: "Pyraminx", value: "pyraminx" },
  { label: "4x4", value: "4x4" },
  { label: "2x2", value: "2x2" },
  { label: "5x5", value: "5x5" },
  { label: "3x3", value: "3x3" },
  { label: "6x6", value: "6x6" },
  { label: "3x3 OH", value: "3x3 OH" },
  { label: "7x7", value: "7x7" },
  { label: "3x3 Blindfold", value: "3x3 Blindfold" },
  { label: "Skewb", value: "skewb" },
];

const BOOLEANOPTIONS = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const JoinUsView = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const JoinUsSchema = Yup.object().shape({
    name: Yup.string().required("Full name is required"),
    dateOfBirth: Yup.mixed().nullable().required("Date of Birth is required"),
    email: Yup.string().required("Email is required"),
    country: Yup.string().required("Country of Residence is required"),
    countryCode: Yup.number()
      .min(2, "Country code must be at least 2 characters long")
      .required("Country code is required"),
    contact: Yup.number()
      .min(2, "Number must be at least 8 characters long")
      .required("Contact is required"),
    description: Yup.string()
      .required("A brief description is required")
      .min(6, "Mininum 30 characters"),
    languages: Yup.array().min(1, "Choose at least one option"),
    cubingExperience: Yup.number()
      .min(1, "You must have at least 1 year of cubing experience")
      .required("Contact is required"),
    concepts: Yup.array().min(1, "Choose at least one option"),
    ftfOnline: Yup.string().required("Have you had any prior experience?"),
  });

  const defaultValues = {
    name: "",
    dateOfBirth: null,
    email: "",
    country: "",
    countryCode: 0,
    contact: 0,
    languages: [],
    description: "",
    cubingExperience: 0,
    concepts: [],
    wca: "",
    ftfOnline: "",
    teachingHours: 0,
  };

  const methods = useForm({
    resolver: yupResolver(JoinUsSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    setErrorMsg("");
    try {
      const formData = {
        type: "trainer interest",
        name: data.name,
        dateOfBirth: new Date(data.dateOfBirth),
        email: data.email,
        country: data.country,
        countryCode: data.countryCode,
        languages: data.languages,
        contact: data.contact,
        description: data.description,
        cubingExperience: data.cubingExperience,
        concepts: data.concepts,
        wca: data.wca,
        ftfOnline: data.ftfOnline,
        teachingHours: data.teachingHours,
      };
      const { error } = await supabase.from("forms").insert(formData);
      console.log(error);
      if (error) throw "Something went wrong";
      enqueueSnackbar("Thank you for your interest");
      reset(defaultValues);
    } catch (error) {
      setErrorMsg(error);
    }
  });

  return (
    <MainLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${CoverImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: 375,
            color: "common.white",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            sx={{ mx: 3 }}
          >
            <img src={JoinUsImage} width={250} alt="join us image" />
            <Typography variant="h3" textAlign="center">
              Join us as an online cube trainer
            </Typography>
          </Stack>
        </Box>
        <Container maxWidth="lg" sx={{ pt: 8 }}>
          <Typography variant="body1" color="text.secondary">
            Fill up this form as comprehensively as possible for us to consider
            your application as an online cube trainer.
            <br />
            <br />
            All applicants will be subjected to an <b>interview process</b>{" "}
            after a successful submission of this form
          </Typography>

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2} sx={{ mt: 6 }}>
              <Typography variant="subtitle1">Personal Information</Typography>
              {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
              <RHFTextField name="name" label="Full Name" />
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label="Date of Birth"
                    format="dd/MM/yyyy"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
              <RHFTextField name="email" label="Email address" />
              <RHFAutocomplete
                name="country"
                label="Country of Residence"
                options={countries.map((country) => country.label)}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => {
                  const { code, label } = countries.filter(
                    (country) => country.label === option
                  )[0];

                  if (!label) {
                    return null;
                  }

                  return (
                    <li {...props} key={label}>
                      <Iconify
                        key={label}
                        icon={`circle-flags:${code.toLowerCase()}`}
                        width={28}
                        sx={{ mr: 1 }}
                      />
                      {label} ({code})
                    </li>
                  );
                }}
              />{" "}
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "1fr 5fr",
                }}
              >
                <RHFTextField
                  className="noNumberSpinner"
                  name="countryCode"
                  label="Country Code"
                  type="number"
                />
                <RHFTextField
                  className="noNumberSpinner"
                  name="contact"
                  label="Contact Number"
                  type="number"
                />
              </Box>
            </Stack>
            <Stack spacing={2} sx={{ mt: 6 }}>
              <Typography variant="subtitle1">Spoken Languages</Typography>
              <Typography variant="caption">
                You may pick more than 1 option
              </Typography>
              <RHFMultiCheckbox name="languages" options={LANGUAGEOPTIONS} />
            </Stack>
            <Stack spacing={2} sx={{ mt: 6 }}>
              <Typography variant="subtitle1">Cubing Background</Typography>
              <RHFTextField
                name="description"
                label="Brief Description"
                multiline
                rows={3}
                helperText="Include a short description of how you started cubing, why you enjoy cubing and/or your personal cubing achievements"
              />
              <RHFTextField
                className="noNumberSpinner"
                name="cubingExperience"
                label="Years of Cubing Experience"
                type="number"
              />
            </Stack>
            <Stack spacing={2} sx={{ mt: 6 }}>
              <Typography variant="subtitle2">Cubing Specialty</Typography>
              <Typography variant="caption">
                You may pick more than 1 option
              </Typography>
              <RHFMultiCheckbox
                name="concepts"
                options={SPECIALTYOPTIONS}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              />
              <RHFTextField name="wca" label="WCA Personal Profile Link" />
            </Stack>
            <Stack spacing={2} sx={{ mt: 6 }}>
              <Typography variant="subtitle1">
                Cube Trainer Experience
              </Typography>
              <RHFSelect
                name="ftfOnline"
                label="Have you taught cubing face-to-face or online before?"
              >
                {BOOLEANOPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelect>
              <RHFTextField
                className="noNumberSpinner"
                name="teachingHours"
                type="number"
                label="What is the estimated number of teaching hours?"
              />
            </Stack>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              loading={isSubmitting}
              sx={{ mt: 6 }}
            >
              Submit now
            </LoadingButton>
          </FormProvider>
        </Container>
      </Box>
    </MainLayout>
  );
};
