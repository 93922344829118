import { Box, Container, Grid, Typography } from "@mui/material";
import { Trainer } from "../components/trainer-card";
import { useCollection, useCollection_s } from "../hooks/useCollection";
import Filler from "../assets/background/filler.png";
import Filler2 from "../assets/background/filler_2.png";
import { SkeletonPost } from "../components/skeleton/skeleton-card";
import { Overlay } from "../components/common/overlay";
import { useAuthContext } from "../hooks/useContext";
import { useEffect, useState } from "react";
import supabase from "../supabase/config";
import { MainLayout } from "../components/main-layout";

export const TrainersView = () => {
  const { user } = useAuthContext();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTrainers = async () => {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from("users")
          .select()
          .eq("userType", "trainer");
        if (error) {
          throw new Error(`Error fetching trainers: ${error.message}`);
        }
        setData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTrainers();
  }, []); // Empty dependency array for initial fetch

  return (
    <MainLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
          position: "relative",
        }}
      >
        {!user && <Overlay />}
        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
          <Typography variant="h3" sx={{ mt: 3 }}>
            Cubing Class | Trainers
          </Typography>
          <Box
            gap={4}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
            }}
            sx={{ pt: 5 }}
          >
            {loading
              ? [...Array(8)].map((_, i) => <SkeletonPost key={i} />)
              : data &&
                data.map((trainer) => (
                  <Trainer trainer={trainer} key={trainer.id} />
                ))}
          </Box>
        </Container>
        <Box sx={{ position: "absolute", left: 0, top: -50, zIndex: 0 }}>
          <img src={Filler2} alt="filler image 1" />
        </Box>
        <Box sx={{ position: "absolute", right: 0, bottom: -64, zIndex: 0 }}>
          <img src={Filler} alt="filler image 2" />
        </Box>
      </Box>
    </MainLayout>
  );
};
