import { Avatar, Box, Button, Card, Typography } from "@mui/material";
import Label from "../common/label";
import { Iconify } from "../../utils/iconify";
import {
  formatTimestampEEEDDMMMYYY,
  getTimeFromTimestamp,
} from "../../utils/time";
import { DialogConfirm } from "../common/dialog-confirm";
import { useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { useSnackbar } from "../snackbar";
import supabase from "../../supabase/config";

export default function LessonUser({ lesson, updateLessonStatus }) {
  // console.log(lesson);
  const theme = useTheme();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const buttonRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const copyToClipboard = () => {
    if (buttonRef.current) {
      const buttonText = buttonRef.current.textContent;
      const tempInput = document.createElement("input");
      document.body.appendChild(tempInput);
      tempInput.value = buttonText;
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      enqueueSnackbar("copied to clipboard");
    }
  };

  return (
    <Card>
      <Box
        sx={{
          m: 3,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                alignSelf: "start",
                display: { xs: "flex", md: "none" },
                gap: 2,
                mb: 2,
                alignItems: "center",
              }}
            >
              <Typography
                variant="caption"
                sx={{ display: { xs: "none", md: "inline" } }}
              >
                UID
              </Typography>
              <Button
                size="medium"
                endIcon={<Iconify icon="bx:copy" width={18} />}
                ref={buttonRef}
                onClick={copyToClipboard}
                sx={{
                  backgroundColor: "rgba(145,158,171,0.08)",
                }}
              >
                <Typography variant="caption">{lesson.id}</Typography>
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Label type={lesson.status} />
              <Box
                sx={{
                  alignSelf: "start",
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  display: { xs: "none", md: "flex" },
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ display: { xs: "none", md: "inline" } }}
                >
                  UID
                </Typography>
                <Button
                  size="medium"
                  endIcon={<Iconify icon="bx:copy" width={18} />}
                  ref={buttonRef}
                  onClick={copyToClipboard}
                  sx={{
                    backgroundColor: "rgba(145,158,171,0.08)",
                  }}
                >
                  <Typography variant="caption">{lesson.id}</Typography>
                </Button>
              </Box>
            </Box>
            <Typography variant="h5" sx={{ mt: 2 }}>
              1-1 session with {lesson.trainerName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                mt: 2,
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box sx={{ display: "flex", gap: 1 }}>
                <Iconify icon="fluent:calendar-32-regular" width={24} />
                <Typography variant="body2">
                  {formatTimestampEEEDDMMMYYY(lesson.startTime)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Iconify icon="basil:clock-outline" width={24} />
                <Typography variant="body2">
                  {`${getTimeFromTimestamp(
                    lesson.startTime
                  )} - ${getTimeFromTimestamp(lesson.endTime)}`}
                </Typography>
              </Box>
            </Box>
            <Typography variant="body2" sx={{ mt: 2 }}>
              {lesson.areaOfFocus}
            </Typography>
            <Box
              sx={{
                mt: 3,
                alignItems: "center",
                gap: 2,
                display: "flex",
              }}
            >
              {lesson.status !== "cancelled" &&
                lesson.lessonStatus !== "started" && (
                  <Button
                    component="a"
                    size="medium"
                    // sx={{ mr: 2 }}
                    variant="outlined"
                    onClick={() =>
                      window.open(
                        "https://wa.me/6588320702?text=Hello%2C%20I%20have%20a%20question%20regarding%20Mofunclass"
                      )
                    }
                  >
                    Request to Cancel Lesson
                  </Button>
                )}
              {lesson.lessonStatus === "started" && (
                <Button
                  component="a"
                  size="medium"
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.secondary.main,
                    ":hover": {
                      backgroundColor: theme.palette.secondary.lighter,
                    },
                  }}
                  onClick={() =>
                    window.open(lesson.trainerMeetingLink, "_blank")
                  }
                >
                  Join Lesson
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Avatar
          src={lesson.trainerPhotoUrl}
          sx={{
            width: 128,
            height: 128,
            display: { xs: "none", md: "inline-flex" },
          }}
        />
      </Box>
    </Card>
  );
}
