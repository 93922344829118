import { Box, Button, Typography } from "@mui/material";
import { Iconify } from "../../utils/iconify";
import { useSnackbar } from "../snackbar";
import { useRef } from "react";

export const CopyToClipboard = ({ label, text, variant }) => {
  const { enqueueSnackbar } = useSnackbar();
  const buttonRef = useRef(null);

  const configureVariant = () => {
    switch (variant) {
      case "success":
        return {
          backgroundColor: "rgba(0, 184,217,0.16)",
          color: "#006C9C",
          active: "rgba(0, 184,217,0.2)",
        };
    }
  };

  const copyToClipboard = () => {
    if (buttonRef.current) {
      const buttonText = buttonRef.current.textContent;
      const tempInput = document.createElement("input");
      document.body.appendChild(tempInput);
      tempInput.value = buttonText;
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      enqueueSnackbar("copied to clipboard");
    }
  };

  return (
    <Box
      sx={{
        alignSelf: "start",
        display: "flex",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Typography variant="subtitle2" color="text.secondary">
        {label}
      </Typography>
      <Button
        size="medium"
        endIcon={
          <Iconify icon="bx:copy" width={18} color={configureVariant().color} />
        }
        ref={buttonRef}
        onClick={copyToClipboard}
        sx={{
          backgroundColor: configureVariant().backgroundColor,
          ":hover": {
            backgroundColor: configureVariant().active,
          },
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ color: configureVariant().color }}
        >
          {text}
        </Typography>
      </Button>
    </Box>
  );
};
