import {
  Avatar,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Iconify } from "../../utils/iconify";
import {
  formatTimestampDDMMMYYY,
  getTimeFromTimestamp,
} from "../../utils/time";
import { Link } from "react-router-dom";

export default function DialogBookingConfimed({
  onClose,
  open,
  trainer,
  timeslot,
}) {
  return (
    <Dialog
      onClose={onclose}
      open={!!open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "480px", // Set your width here
          },
        },
      }}
    >
      <Stack spacing={3} sx={{ m: 3 }}>
        <Typography variant="h6" textAlign="center">
          Lesson Requested!
        </Typography>
        <Typography variant="body2" textAlign="center">
          We have sent a request to your trainer. Once they accept, you will
          receive a confirmation email and be able to access the lesson on your
          home page.
        </Typography>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            pb: { xs: 5, md: 10 },
          }}
        >
          <Avatar src={trainer.photoUrl} sx={{ width: 64, height: 64 }} />
          <Typography variant="body2">
            Cubing Class with <b>{trainer.name}</b>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 2, md: 3 },
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Iconify icon="fluent:calendar-32-regular" width={16} />
              <Typography variant="body2">
                {formatTimestampDDMMMYYY(timeslot.startTime)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Iconify icon="basil:clock-outline" width={16} />
              <Typography variant="body2">
                {`${getTimeFromTimestamp(
                  timeslot.startTime
                )} - ${getTimeFromTimestamp(timeslot.endTime)}`}{" "}
                SGT
              </Typography>
            </Box>
          </Box>
        </Box>
        <Link to="/lessons">
          <Button variant="contained" fullWidth>
            Manage Booking
          </Button>
        </Link>
      </Stack>
    </Dialog>
  );
}
