import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
// routes
import { useNavigate, useSearchParams } from "react-router-dom";
import { Iconify } from "../utils/iconify";
import RHFTextField from "../components/hook-form/rhf-text-field";
import { useBoolean } from "../components/hook-form/use-boolean";
import FormProvider from "../components/hook-form/form-provider";
import { useEffect } from "react";
import supabase from "../supabase/config";
import PasswordIcon from "../assets/icons/PasswordIcon";
import { Container, Link } from "@mui/material";
import { useSnackbar } from "../components/snackbar";

export default function ResetPasswordView() {
  const password = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const checkTokenExpiry = async () => {
      const { error } = await supabase.rpc("check_token_expiry", {
        p_token: searchParams.get("token"),
        p_userid: searchParams.get("id"),
      });
      if (error) navigate("/404");
    };
    checkTokenExpiry();
  }, []);

  const NewPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const defaultValues = {
    password: "",
    confirmPassword: "",
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(NewPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { error } = await supabase.rpc("update_user_password", {
        p_user_id: searchParams.get("id"),
        p_new_password: data.password,
      });
      if (error) throw error;
      else navigate("/login");
      enqueueSnackbar("Password changed");
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      reset();
    }
  });

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <RHFTextField
        name="password"
        label="Password"
        type={password.value ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify
                  icon={
                    password.value ? "solar:eye-bold" : "solar:eye-closed-bold"
                  }
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <RHFTextField
        name="confirmPassword"
        label="Confirm New Password"
        type={password.value ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify
                  icon={
                    password.value ? "solar:eye-bold" : "solar:eye-closed-bold"
                  }
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Update Password
      </LoadingButton>

      <Link
        href="/"
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: "center",
          display: "inline-flex",
          textDecoration: "none !important",
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Return Home
      </Link>
    </Stack>
  );

  const renderHead = (
    <>
      <PasswordIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ my: 5, textAlign: "center" }}>
        <Typography variant="h3">Reset your password</Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Please enter your new password below
          <br />
          and try not to forget it again!
        </Typography>
      </Stack>
    </>
  );

  return (
    <Container maxWidth="xs" sx={{ mt: 10 }}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderHead}

        {renderForm}
      </FormProvider>
    </Container>
  );
}
