import { Box, Button, Stack, Typography } from "@mui/material";
import OverlayImage from "../../assets/background/overlay_5.png";
import CubeIcon from "../../assets/components/cube-icon_w.png";
import { Iconify } from "../../utils/iconify";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export const Overlay = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${OverlayImage})`,
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 3,
        height: "100vh",
        width: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundColor: "transparent",
        backdropFilter: "blur(10px)",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          position: "absolute",
          bottom: "100px",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Login to view all trainers</Typography>
        <Typography variant="body2">
          Want to see available trainers? Login to view more
        </Typography>
        <Box sx={{ display: "flex", gap: 2, mt: 2, flexDirection: "column" }}>
          <Button
            fullWidth
            onClick={() =>
              window.open(
                "https://wa.me/6588320702?text=Hello%2C%20I%20have%20a%20question%20regarding%20Mofunclass"
              )
            }
            variant="outlined"
            startIcon={<Iconify icon="mingcute:phone-fill" width={24} />}
          >
            Contact Us
          </Button>
          <Link to="/login">
            <Button
              fullWidth
              variant="contained"
              startIcon={
                <img
                  src={CubeIcon}
                  height={20}
                  style={{ marginRight: "10px" }}
                />
              }
              sx={{ bgcolor: "#007867", ":hover": { bgcolor: "#006c5d" } }}
            >
              Login
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
