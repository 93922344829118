import { useTheme } from "@emotion/react";
import {
  alpha,
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@mui/material";
import Overlay from "../../assets/background/overlay.png";
import { bgGradient, textGradient } from "../../utils/css";
import { Iconify } from "../../utils/iconify";
import HeroImage from "../../assets/images/mofunhand.png";
import AboutIcon from "../../assets/components/about-icon.png";
import { Link } from "react-router-dom";
import { useSnackbar } from "../snackbar";

const gradientText = {
  // backgroundImage: "linear-gradient(300deg, #FFAB00, #00AB55)",
  backgroundImage:
    "linear-gradient(300deg, #FFAB00 0%, #00AB55 25%, #FFAB00 50%, #00AB55 75%, #FFAB00 100%)",
  WebkitBackgroundClip: "text",
  backgroundClip: "text",
  color: "transparent",
  backgroundSize: "200% 100%",
  animation: "gradientAnimation 3s linear infinite",
};

export default function HomeHero({ scrollToSection }) {
  const theme = useTheme();

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: "15vh",
          display: { xs: "none", md: "block" },
        }}
      >
        <img src={HeroImage} width={550} />
      </Box>
      <Box
        sx={{
          ...bgGradient({
            color: alpha(
              theme.palette.background.default,
              theme.palette.mode === "light" ? 0.9 : 0.94
            ),
            imgUrl: Overlay,
            position: "relative",
          }),
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            py: { xs: 10, md: 20 },
          }}
        >
          <Grid
            container
            sx={{
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
            spacing={5}
          >
            <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
              <Typography variant="h2">Cubing with</Typography>
              <Typography
                variant="h1"
                sx={{
                  mb: 3,
                  letterSpacing: { xs: 5, md: 8 },
                  fontFamily: "Fredoka, sans-serif",
                }}
                style={gradientText}
              >
                MOFUNCLASS
              </Typography>
              <Typography variant="body2" sx={{ mb: 5 }}>
                The First Online Platform for Cubing Classes
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  flexDirection: { xs: "column", md: "row" }, // Display buttons in a column on xs screens
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={
                    <img src={AboutIcon} alt="about icon" width={24} />
                  }
                  onClick={scrollToSection}
                >
                  About Mofunclass
                </Button>
                <Link to="/trainers">
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={
                      <Iconify
                        icon="fluent:people-16-filled"
                        width={24}
                        color="#fff"
                      />
                    }
                  >
                    View Trainers
                  </Button>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} textAlign="center">
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <img src={HeroImage} style={{ width: "80%" }} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
