import { Box, Container, Typography } from "@mui/material";
import Mofunclass from "../../assets/logo/Mofunclass.png";

export default function HomeFooter() {
  return (
    <Box
      component="footer"
      sx={{
        mb: -64,
        py: { xs: 3, md: 5 },
        textAlign: "center",
        position: "relative",
        bgcolor: "background.default",
      }}
    >
      <Container>
        <Box>
          <img src={Mofunclass} width={100} />
        </Box>

        <Typography variant="caption" component="div">
          © All rights reserved v1.18
        </Typography>
      </Container>
    </Box>
  );
}
