// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import { useTheme, alpha } from "@mui/material/styles";
import CoverPhotoImage from "../../assets/background/overlay_4.png";

// ----------------------------------------------------------------------

export default function ProfileCover({ name, country, photoUrl }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundImage: `url(${CoverPhotoImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        height: 1,
        color: "common.white",
        position: "relative",
      }}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{
          left: { md: 24 },
          bottom: { md: 75 },
          zIndex: { md: 10 },
          pt: { xs: 6, md: 0 },
          position: { md: "absolute" },
        }}
      >
        <Avatar
          src={photoUrl}
          alt={name}
          sx={{
            mx: "auto",
            width: { xs: 64, md: 128 },
            height: { xs: 64, md: 128 },
            border: `solid 2px ${theme.palette.common.white}`,
          }}
        />

        <ListItemText
          sx={{
            mt: 3,
            ml: { md: 3 },
            textAlign: { xs: "center", md: "unset" },
          }}
          primary={name}
          secondary={country}
          primaryTypographyProps={{
            typography: "h4",
          }}
          secondaryTypographyProps={{
            mt: 0.5,
            color: "inherit",
            component: "span",
            typography: "body2",
            sx: { opacity: 0.48 },
          }}
        />
      </Stack>
    </Box>
  );
}
