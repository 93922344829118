import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { LessonContext } from "../context/LessonContext";
export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error("useAuthContext must be used inside an AuthContextProvider");
  }

  return context;
};

export const useLessonContext = () => {
  const context = useContext(LessonContext);

  if (!context) {
    throw Error("useAuthContext must be used inside an AuthContextProvider");
  }

  return context;
};
