import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import TrainerProfilesImage from "../../assets/images/trainer-profiles.png";
import Overlay from "../../assets/background/overlay_3.jpg";
import { Iconify } from "../../utils/iconify";
import { useTheme } from "@emotion/react";
import { bgGradient } from "../../utils/css";
import { Link } from "react-router-dom";

const gradientTextStyles = {
  backgroundImage: "linear-gradient(to right, #FFAB00, #00AB55)",
  WebkitBackgroundClip:
    "text" /* For webkit-based browsers like Chrome and Safari */,
  backgroundClip: "text",
  color:
    "transparent" /* Make the text transparent to show the gradient background */,
};

export default function HomeTrainers() {
  const theme = useTheme();
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          ...bgGradient({
            color: alpha(
              theme.palette.background.default,
              theme.palette.mode === "light" ? 0.9 : 0.94
            ),
            imgUrl: Overlay,
            position: "relative",
          }),
        }}
      >
        <Container
          sx={{
            py: { xs: 10, md: 15 },
          }}
        >
          <Grid
            container
            sx={{
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
            spacing={5}
          >
            <Grid item xs={12} md={7}>
              <img src={TrainerProfilesImage} style={{ width: "100%" }} />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              <Typography
                variant="h2"
                sx={{ my: 3 }}
                style={gradientTextStyles}
              >
                Qualified Cubing Trainers
              </Typography>
              <Link to="/trainers">
                <Button
                  variant="contained"
                  size="large"
                  startIcon={
                    <Iconify
                      icon="fluent:people-16-filled"
                      width={24}
                      color="#fff"
                    />
                  }
                >
                  View Trainers
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
