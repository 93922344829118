import { styled } from "@mui/material/styles";
import { MainNavbar } from "./main-navbar";
import { ContactUs } from "./common/contact-us";
import { useState } from "react";
import { MainSidebar } from "./main-sidebar";

const MainLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingTop: 64,
  paddingBottom: 44,
}));

export const MainLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <MainLayoutRoot>
      <MainSidebar
        onClose={() => setIsSidebarOpen(false)}
        open={isSidebarOpen}
      />
      <MainNavbar onOpenSidebar={() => setIsSidebarOpen(true)} />
      {children}
      <ContactUs />
    </MainLayoutRoot>
  );
};
