import * as Yup from "yup";
import {
  Alert,
  Avatar,
  Box,
  Dialog,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Iconify } from "../../utils/iconify";
import { useTheme } from "@emotion/react";
import {
  formatTimestampDDMMMYYY,
  getTimeFromTimestamp,
} from "../../utils/time";
import { RHFSelect } from "../hook-form/rhf-select";
import FormProvider from "../hook-form/form-provider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFMultiCheckbox } from "../hook-form/rhf-multicheckbox";
import RHFTextField from "../hook-form/rhf-text-field";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useAuthContext } from "../../hooks/useContext";
import supabase from "../../supabase/config";
import { useSnackbar } from "../snackbar";
const FOCUSOPTIONS = [
  {
    label: "Beginner | Learn to solve a cube",
    value: "Beginner | Learn to solve a cube",
  },
  { label: "CFOP | Beginner", value: "CFOP | Beginner" },
  { label: "CFOP | Advanced", value: "CFOP | Advanced" },
  { label: "CFOP | Advanced Plus", value: "CFOP | Advanced Plus" },
];

const CONCEPTOPTIONS = [
  { label: "Solving 1st Layer", value: "Solving 1st Layer" },
  { label: "Solving last Layer", value: "Solving last Layer" },
  { label: "2 Look PLL", value: "2 Look PLL" },
  { label: "Cross", value: "Cross" },
  { label: "Solving 2nd Layer", value: "Solving 2nd Layer" },
  { label: "2 Look OLL", value: "2 Look OLL" },
  { label: "F2L", value: "F2L" },
];

const defaultValues = {
  areaOfFocus: "",
  concepts: [],
};

export default function DialogBookLesson({
  onClose,
  open,
  trainer,
  timeslot,
  selDate,
  nextStep,
}) {
  const theme = useTheme();
  const [errorMsg, setErrorMsg] = useState("");
  const { user, authIsReady } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  // console.log(timeslot);
  const BookLessonSchema = Yup.object().shape({
    areaOfFocus: Yup.string().required("Area of focus is required"),
    concepts: Yup.array(),
    achieve: Yup.string().required(
      "What would you like to achieve is requried"
    ),
  });

  const methods = useForm({
    resolver: yupResolver(BookLessonSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (user.data.credits < trainer.creditsPerLesson)
        throw "Insufficient Credits";

      const { availabilityError } = await supabase
        .from("availability")
        .update({
          reservedBy: user.id,
        })
        .eq("id", timeslot.id);

      if (availabilityError) throw "Something went wrong";
      console.log(availabilityError);

      const { error: usersError } = await supabase.rpc("decrement", {
        x: trainer.creditsPerLesson,
        row_id: user.id,
      });
      if (usersError) throw "Something went wrong";
      console.log(usersError);

      const bookingData = {
        type: "requested",
        // student data
        studentId: user.id,
        // trainer data
        trainerId: trainer.id,
        // availability data
        availabilityId: timeslot.id,
      };

      const { error: bookingsError } = await supabase
        .from("bookings")
        .insert(bookingData);

      if (bookingsError) throw "Something went wrong";

      const lessonData = {
        achieve: data.achieve,
        areaOfFocus: data.areaOfFocus,
        concepts: data.concepts,
        creditsRequired: trainer.creditsPerLesson,
        studentId: user.id,
        trainerId: trainer.id,
        availabilityId: timeslot.id,
      };

      const { error: lessonsError } = await supabase
        .from("lessons")
        .insert(lessonData);
      console.log(lessonsError);
      if (lessonsError) throw "Something went wrong";

      nextStep();
    } catch (error) {
      reset();
      enqueueSnackbar(error, "error");
    }
  });

  return (
    <Dialog
      onClose={onClose}
      open={!!open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "960px", // Set your width here
          },
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          borderRadius: "50%",
          width: 30,
          height: 30,
          backgroundColor: "rgba(145, 158,171,0.2)",
          ":hover": {
            backgroundColor: "rgba(145, 158,171,0.3)",
          },
          position: "absolute",
          right: 12,
          top: 20,
        }}
      >
        <Iconify icon="ph:x" />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          m: 3,
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            width: { xs: "100%", md: "50%" },
          }}
        >
          <Typography variant="h6">Cubing Class Details</Typography>

          <Box sx={{ display: "flex", gap: 2, alignItems: "flex-start" }}>
            <Iconify icon="mdi:clock" width={20} />
            <Box>
              <Typography variant="subtitle2">Session Duration</Typography>
              <Typography variant="subtitle1">1 hour</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2, alignItems: "flex-start" }}>
            <Iconify icon="fluent:location-20-filled" width={20} />
            <Box>
              <Typography variant="subtitle2">Location</Typography>
              <Typography variant="subtitle1">Mofunclass Zoom</Typography>
            </Box>
          </Box>

          <Divider />
          <Box>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Trainer
            </Typography>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Avatar src={trainer?.photoUrl} />
              <Box>
                <Typography variant="subtitle1">{trainer?.name}</Typography>
                <Typography variant="body2">{trainer?.country}</Typography>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ display: { md: "none" } }} />
        </Box>
        <Box
          sx={{
            width: "1px",
            backgroundColor: "#E6E8F0",
            display: { xs: "none", md: "block" },
          }}
        />
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Typography variant="h6">Confirm your lesson slot</Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Iconify icon="fluent:calendar-32-regular" width={20} />
                <Typography variant="body2">
                  {timeslot && formatTimestampDDMMMYYY(timeslot.startTime)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Iconify icon="basil:clock-outline" width={20} />
                <Typography variant="body2">
                  {timeslot && getTimeFromTimestamp(timeslot.startTime)}
                </Typography>
              </Box>
            </Box>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Stack spacing={3}>
                <RHFSelect name="areaOfFocus" label="Area of Focus">
                  {FOCUSOPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <Box>
                  <Typography sx={{ fontSize: 12 }}>
                    I have mastered the following concepts
                  </Typography>
                  <RHFMultiCheckbox
                    name="concepts"
                    options={CONCEPTOPTIONS}
                    sx={{
                      display: { md: "grid" },
                      gridTemplateColumns: "repeat(2, 1fr)",
                    }}
                  />
                </Box>
                <RHFTextField
                  name="achieve"
                  label="What would you like to achieve from this lesson?"
                  type="text"
                  multiline
                  rows={3}
                />

                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{
                    backgroundColor: "#00A76F",
                    color: "#fff",
                    ":hover": {
                      backgroundColor: theme.palette.secondary.dark,
                    },
                  }}
                >
                  Confirm
                </LoadingButton>
              </Stack>
            </FormProvider>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
